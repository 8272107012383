import moment from "moment";

export default function getDuration(startTime: Date | string, endTime: Date | string): string {
    const milliseconds = moment(endTime).diff(startTime);
    const duration = moment.duration(milliseconds);
    const durationAsDays = Math.floor(duration.asDays());
    const restInHours = duration.hours();
    const restInMinutes = duration.minutes();
    const restInSeconds = duration.seconds();

    const days =
        durationAsDays >= 0
            ? `${durationAsDays}d`
            : `${Math.abs(durationAsDays + 1)}d`

    const hours =
        restInHours > 0
            ? `${restInHours}h`
            : `${Math.abs(restInHours)}h`

    const minutes =
        restInMinutes > 0
            ? `${restInMinutes}m`
            : `${Math.abs(restInMinutes)}m`

    const seconds =
        restInSeconds > 0
            ? `${restInSeconds}s`
            : `${Math.abs(restInSeconds)}s`

    const time =
        (durationAsDays >= 0 && milliseconds >= 86400000) || (durationAsDays < 0 && milliseconds <= -86400000)
            ? `${days} ${hours}`
            : (durationAsDays >= 0 && milliseconds >= 3600000) || (durationAsDays < 0 && milliseconds <= -3600000)
                ? `${hours} ${minutes}`
                : (durationAsDays >= 0 && milliseconds >= 60000) || (durationAsDays < 0 && milliseconds <= -60000)
                    ? `${minutes} ${seconds}`
                    : `${seconds}`

    return durationAsDays >= 0
        ? `${time}`
        : `-${time}`;
}