import {PaletteMode} from "@mui/material";


declare module "@mui/material/styles" {
    interface Palette {
        //primary
        primaryDarker: Palette["primary"];
        primaryDark: Palette["primary"];
        primaryLight: Palette["primary"];
        primaryLighter: Palette["primary"];
        //secondary
        secondaryLight: Palette["primary"];
        secondaryLighter: Palette["primary"];
        secondaryLighter2: Palette["primary"];
        secondaryLighter3: Palette["primary"];
        //tertiary
        tertiaryDark: Palette["primary"];
        tertiary: Palette["primary"];
        tertiaryLight: Palette["primary"];
        //Shadow
        shadowColor: Palette["primary"];
        //black
        black60: Palette["primary"];

        // Em Atendimento
        attending: Palette["primary"];
        // Atrasados
        late: Palette["primary"];
        // Alta
        discharged: Palette["primary"];
        // AFD
        removed: Palette["primary"];
        // Sem Pendência
        done: Palette["primary"];
        // Pendência Futura
        waiting: Palette["primary"];
        // Sem Prescrição
        no_prescription: Palette["primary"];
        // Telemedicina
        tele: Palette["primary"];
    }

    interface PaletteOptions {
        //primary
        primaryDarker: PaletteOptions["primary"];
        primaryDark: PaletteOptions["primary"];
        primaryLight: PaletteOptions["primary"];
        primaryLighter: PaletteOptions["primary"];
        //secondary
        secondaryLight: PaletteOptions["primary"];
        secondaryLighter: PaletteOptions["primary"];
        secondaryLighter2: PaletteOptions["primary"];
        secondaryLighter3: PaletteOptions["primary"];
        //tertiary
        tertiaryDark: PaletteOptions["primary"];
        tertiary: PaletteOptions["primary"];
        tertiaryLight: PaletteOptions["primary"];
        //Shadow
        shadowColor: PaletteOptions["primary"];

        // Em Atendimento
        attending: PaletteOptions["primary"];
        // Atrasados
        late: PaletteOptions["primary"];
        // Alta
        discharged: PaletteOptions["primary"];
        // AFD
        removed: PaletteOptions["primary"];
        // Sem Pendência
        done: PaletteOptions["primary"];
        // Pendência Futura
        waiting: PaletteOptions["primary"];
        // Sem Prescrição
        no_prescription: PaletteOptions["primary"];
        // Telemedicina
        tele: PaletteOptions["primary"];
    }
}

let defaultTheme = (mode: PaletteMode) => ({
    palette: {
        mode: mode,
        common: {white: "#F7FFF7"},

        // primary colors
        primaryDarker: {main: "#000042"},
        primaryDark: {main: "#171761"},
        primary: {
            dark: "#000042",
            main: "#000083",
            light: "#3C3CBA",
        },
        primaryLight: {main: "#2727A1"},
        primaryLighter: {main: "#3C3CBA"},

        // secondary colors
        secondary: {
            dark: '#4949F3',
            main: "#4949F3",
            light: '#7979F6',
        },
        secondaryLight: {main: "#7979F6"},
        secondaryLighter: {main: "#9F9FF9"},
        secondaryLighter2: {main: "#C5C5FB"},
        secondaryLighter3: {main: "#ECECFE"},

        // tertiary colors
        tertiaryDark: {main: "#70D49F"},
        tertiary: {
            dark: '#70D49F',
            main: "#7EEDB1",
            light: '#84FABB',
        },
        tertiaryLight: {main: "#84FABB"},

        // shadow
        shadowColor: {main: "#00000012"},

        // generals
        error: {
            dark: "#B00020",
            main: "#E73A3A",
            light: "#FF6565",
        },
        warning: {
            dark: "#EBB500",
            main: "#F9CA24",
            light: "#F6E58D",
        },
        info: {
            dark: "#4949F3",
            main: "#4949F3",
            light: "#7979F6",
        },
        success: {
            dark: "#34A56C",
            main: "#4FC66A",
            light: "#4FC66A",
        },

        // Em Atendimento
        attending: {
            dark: "#d7ce0d",
            main: "#FFF50D",
            light: "#faf12b",
        },
        // Atrasados
        late: {
            dark: "#b00b0b",
            main: "#FB0909",
            light: "#fa3a3a",
        },
        // Alta
        discharged: {
            dark: "#000000",
            main: "#000000",
            light: "#464545",
        },
        // AFD
        removed: {
            dark: "#3333b6",
            main: "#4949FE",
            light: "#7272f8",
        },
        // Sem Pendência
        done: {
            dark: "#498a67",
            main: "#7EEDB1",
            light: "#a0ecc3",
        },
        // Pendência Futura
        waiting: {
            dark: "#868686",
            main: "#C4C4C4",
            light: "#ffffff",
        },
        // Sem Prescrição
        no_prescription: {
            dark: "#c0780b",
            main: "#F6980B",
            light: "#f8ae40",
        },
        // Telemedicina
        tele:{
            dark: "#870bb9",
            main: "#b30bf6",
            light: "#c038f6",
        },

        ...(mode === 'dark' && {
            background: {
                default: "#333333",
                paper: "#4D4D4D",
            },
        }),
        // background: { default: "#F4F7FF", paper: "#FFFFFF" },
        // action:{
        //     hover: 'black',
        // }
    },
    typography: {
        fontFamily: "GeneralSans-Variable",
        fontSize: 12,
        button: {
            textTransform: undefined,
        },
    },
    spacing: 8,
    components: {
        // LIST
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        backgroundColor: '#ff6565',
                        opacity: 1,
                        color: '#F7FFF7'
                    },
                },
            },
        }
    },
});

export default defaultTheme;
