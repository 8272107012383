import React from "react";
import MapView from "../components/Map";
import TopBar from "../components/Bar/TopBar";
import BottomBar from "../components/Bar/BottomBar";

interface IMapProps {
}

const MapPage: React.FC<IMapProps> = () => {
    return (
        <>
            <MapView/> {/*Google Component Must Be The First*/}
            <TopBar/>
            <BottomBar/>
        </>
    );
};

export default MapPage;
