export default function handleStatus(status: string, theme: any) {
    const color = status === 'done' ? 'Green'
        : status === 'late' ? 'Red'
            : status === 'waiting' ? 'Grey'
                : status === 'attending' ? 'Yellow'
                    : status === 'dischargedLate' ? 'Black'
                        : status === 'discharged' ? 'Black'
                            : status === 'removed' ? 'Blue'
                                : status === 'no_prescription' ? 'Orange'
                                    : status === 'no_geo' ? 'White'
                                        : status === 'tele' ? 'Purple'
                                            : 'none'

    const hex_dark = status === 'done' ? theme.palette.done.dark
        : status === 'late' ? theme.palette.late.dark
            : status === 'waiting' ? theme.palette.waiting.dark
                : status === 'attending' ? theme.palette.attending.dark
                    : status === 'dischargedLate' ? theme.palette.discharged.dark
                        : status === 'discharged' ? theme.palette.discharged.dark
                            : status === 'removed' ? theme.palette.removed.dark
                                : status === 'no_prescription' ? theme.palette.no_prescription.dark
                                    : status === 'no_geo' ? theme.palette.common.white
                                        : status === 'tele' ? theme.palette.tele.dark
                                            : 'none'

    const hex = status === 'done' ? theme.palette.done.main
        : status === 'late' ? theme.palette.late.main
            : status === 'waiting' ? theme.palette.waiting.main
                : status === 'attending' ? theme.palette.attending.main
                    : status === 'dischargedLate' ? theme.palette.discharged.main
                        : status === 'discharged' ? theme.palette.discharged.main
                            : status === 'removed' ? theme.palette.removed.main
                                : status === 'no_prescription' ? theme.palette.no_prescription.main
                                    : status === 'no_geo' ? theme.palette.common.white
                                        : status === 'tele' ? theme.palette.tele.main
                                            : 'none'

    const hex_light = status === 'done' ? theme.palette.done.main
        : status === 'late' ? theme.palette.late.light
            : status === 'waiting' ? theme.palette.waiting.light
                : status === 'attending' ? theme.palette.attending.light
                    : status === 'dischargedLate' ? theme.palette.discharged.light
                        : status === 'discharged' ? theme.palette.discharged.light
                            : status === 'removed' ? theme.palette.removed.light
                                : status === 'no_prescription' ? theme.palette.no_prescription.light
                                    : status === 'no_geo' ? theme.palette.common.white
                                        : status === 'tele' ? theme.palette.tele.light
                                            : 'none'

    const iconTemplate = [
        '<?xml version="1.0"?>',
        '<svg width="40px" height="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">',
        '<ellipse cx="11.5" cy="12" rx="11.5" ry="12" fill="{{ color }}"/>',
        '<ellipse cx="11.5" cy="12" rx="9.5" ry="10" fill="white"/>',
        '<path d="M12.9091 5.63774C12.5312 5.45136 12.121 5.34828 11.7039 5.33484C11.2868 5.32139 10.8715 5.39787 10.4834 5.55956C10.0953 5.72126 9.74273 5.96476 9.44723 6.27514C9.15173 6.58552 8.91957 6.95622 8.76498 7.3645H10.7225L12.9091 5.63774ZM14.4795 7.3645C14.2878 6.87611 13.9857 6.44446 13.5995 6.10712L12.0044 7.3645H14.4724H14.4795ZM11.6222 11.8333C12.4406 11.8333 13.2255 11.4909 13.8042 10.8814C14.3828 10.2719 14.7079 9.44522 14.7079 8.58326C14.7031 8.44712 14.6902 8.31144 14.6692 8.17701H8.5753C8.55417 8.31142 8.54122 8.44711 8.53651 8.58326C8.53651 9.44522 8.86162 10.2719 9.4403 10.8814C10.019 11.4909 10.8038 11.8333 11.6222 11.8333ZM8.1508 12.9429V18.3304H11.2429L8.86934 12.7089C8.62193 12.7569 8.38076 12.8354 8.1508 12.9429ZM6.22223 17.1146C6.22298 17.4373 6.34522 17.7465 6.56214 17.9744C6.77907 18.2024 7.07297 18.3304 7.37937 18.3304V13.4642C7.01829 13.7814 6.72757 14.178 6.52765 14.6262C6.32773 15.0744 6.22348 15.5633 6.22223 16.0585L6.22223 17.1146ZM12.3937 15.8958H11.0588L12.0876 18.3304H12.3944C12.7013 18.3304 12.9956 18.202 13.2126 17.9734C13.4296 17.7448 13.5515 17.4348 13.5515 17.1116C13.5515 16.7884 13.4296 16.4784 13.2126 16.2498C12.9956 16.0212 12.7013 15.8928 12.3944 15.8928L12.3937 15.8958ZM13.7821 12.6458H13.3794C12.8285 12.9134 12.229 13.052 11.6222 13.052C11.0155 13.052 10.416 12.9134 9.86501 12.6458H9.68731L10.7161 15.0833H12.3937C12.905 15.0839 13.3952 15.2981 13.7567 15.6789C14.1183 16.0597 14.3217 16.576 14.3222 17.1146C14.3207 17.5555 14.1817 17.9837 13.9266 18.3333H15.8651C16.172 18.3333 16.4663 18.2049 16.6833 17.9764C16.9003 17.7478 17.0222 17.4378 17.0222 17.1146V16.0585C17.0213 15.1537 16.6796 14.2862 16.0722 13.6464C15.4647 13.0066 14.6411 12.6468 13.7821 12.6458Z" fill="#0899BA"/>',
        '</svg>'
    ].join('\n');
    const icon = iconTemplate.replace('{{ color }}', hex);

    return {
        color: color,
        icon: icon,
        hex_dark: hex_dark,
        hex: hex,
        hex_light: hex_light,
    }
}