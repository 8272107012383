import {useQuery, UseQueryResult} from "react-query";
import instance from "../core/api";

function useHealth() {
    const getHealth: UseQueryResult<any, any> = useQuery(
        'health',
        async () => {
            return instance.get(`api/health/integration`).then((res) => res.data)
        },
        {
            refetchInterval: 60000,
        }
    )

    return {
        getHealth
    }
}

export default useHealth;