import React, {useEffect} from "react";
import {Typography, useTheme} from "@mui/material";
import TypographyText from "../index";
import getDuration from "../../../utils/date";

interface ITimer {
    children: any,
    sx?: any,
}

const Timer: React.FC<ITimer> = ({children, sx}: ITimer) => {
    const theme = useTheme();

    const [time, setTime] = React.useState<string>(getDuration(children, new Date()));

    useEffect(() => {
        let timer = setTimeout(() => {
            setTime(getDuration(children, new Date()))
        }, 1000)

        return () => {
            clearTimeout(timer);
        };
    }, [time])

    return (
        <TypographyText align="center" sx={{...sx}}>
            {time}
        </TypographyText>
    );
};

export default Timer;
