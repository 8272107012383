import {Box, Button, IconButton, Tooltip, Typography, useTheme} from "@mui/material";
import React from "react";
import THCSButtonLeft from "../../Icons/THCSButtonLeft";
import _ from "lodash";
import handleText from "../../../utils/text";
import THCSIconDefault from "../../Icons/THCSIconDefault";
import TypographyText from "../../Text";

interface IArrowButton {
    name?: string,
    onClick?: any,
    children?: any,
    position?: any,
}

const ButtonLeft: React.FC<IArrowButton> = ({
                                                name,
                                                onClick,
                                                children,
                                                position,
                                            }: IArrowButton) => {
    const theme = useTheme();
    return (
        <Box
            onClick={
                onClick && onClick(name)
            }
            sx={{
                width: '100%',
                padding: '0px 0px 0px 0px',
                backgroundColor: theme.palette.primary.main,
                "&.MuiButtonBase-root:hover": {
                    backgroundColor: theme.palette.primary.main
                },
                borderRadius: '0px 0px 10px 10px',
                zIndex: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                <THCSButtonLeft fill={theme.palette.common.white} height={'40px'} width={'25px'}
                                color1={theme.palette.common.white} color2={theme.palette.secondary.main}
                                color3={theme.palette.tertiary.main}
                                sx={{marginLeft: '25px', transform: position === 'right' ? 'rotate(180deg)' : 'rotate(0deg)'}}/>

                <TypographyText sx={{
                    display: 'flex',
                    color: theme.palette.common.white,
                    marginLeft: '10px',
                }}>
                    {name}
                </TypographyText>
            </Box>

            {children}


            {/*<Button variant="contained" size="medium"*/}
            {/*        sx={{*/}
            {/*            width: '100%',*/}
            {/*            padding: '0px 0px 0px 0px',*/}
            {/*            backgroundColor: theme.palette.primary.main,*/}
            {/*            "&.MuiButtonBase-root:hover": {*/}
            {/*                backgroundColor: theme.palette.primary.main*/}
            {/*            },*/}
            {/*            borderRadius: '0px 0px 10px 10px',*/}
            {/*            zIndex: 10,*/}
            {/*        }}*/}
            {/*        // onClick={*/}
            {/*        //     onClick && onClick(name)*/}
            {/*        // }*/}
            {/*        startIcon={*/}
            {/*            <THCSButtonLeft fill={theme.palette.common.white} height={'40px'} width={'25px'}*/}
            {/*                            color1={theme.palette.common.white} color2={theme.palette.secondary.main}*/}
            {/*                            color3={'#7EEDB1'} // TODO PUT PALLET*/}
            {/*                            sx={{marginLeft: '25px'}}/>*/}
            {/*        }*/}
            {/*>*/}
            {/*    <Box sx={{*/}
            {/*        display: 'flex',*/}
            {/*        flex: 1,*/}
            {/*        alignItems: 'center',*/}
            {/*    }}*/}
            {/*    >*/}
            {/*        <TypographyText sx={{display: 'flex'}}>*/}
            {/*            {name}*/}
            {/*        </TypographyText>*/}

            {/*        {children}*/}
            {/*    </Box>*/}
            {/*</Button>*/}
        </Box>
    );
};

export default ButtonLeft;
