import React from "react";
import {Box, Divider, IconButton, Typography, useTheme} from "@mui/material";
import handleText from "../../../utils/text";
import {InfoWindowF} from "@react-google-maps/api";
import getDuration from "../../../utils/date";
import TypographyText from "../../Text";
import format from "../../../utils/formatDate";
import Timer from "../../Text/Timer";
import THCSIconClose from "../../Icons/THCSIconClose";
import {GlobalContext} from "../../../contexts/GlobalContext";

interface IMarkerWindows {
    status: string,
    attendance: any,
    LatLng: any,
}

const MarkerWindows: React.FC<IMarkerWindows> = ({status, attendance, LatLng}: IMarkerWindows) => {
    const theme = useTheme()

    const {
        markerClickHandler,
    } = React.useContext(GlobalContext);

    return (
        <Box key={`MarkerWindows-${attendance}`} sx={{
            maxHeight: '150px',
            maxWidth: '300px',
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <TypographyText align={'center'}
                                sx={{fontWeight: 'bold'}}>{handleText(`${attendance.label} - ${attendance.attendance_id}`)}
                </TypographyText>

                <IconButton aria-label="close_windows" sx={{}}
                            onClick={(event) => markerClickHandler(event, attendance, LatLng)}
                >
                    <THCSIconClose width={'10px'} height={'10px'} fill={theme.palette.common.black}/>
                </IconButton>
            </Box>

            <Divider/>

            {
                status === 'attending' ? (
                    <Box sx={{}}>
                        {
                            attendance?.users.map((user: any, index: number) => {
                                return (
                                    <Box>
                                        <Divider/>
                                        <Box sx={{
                                            padding: '10px 10px 10px 10px'
                                        }}>
                                            <TypographyText>{`${handleText(user.name)}(${handleText(user.profession)})`}</TypographyText>
                                            <Box sx={{display: 'flex'}}>
                                                <TypographyText>{`Tempo De Atendimento: `}</TypographyText>
                                                <Timer
                                                    sx={{
                                                        paddingLeft: '5px',
                                                    }}>
                                                    {attendance.time}
                                                </Timer>
                                            </Box>
                                        </Box>
                                        {
                                            attendance?.users?.length === index + 1 && <Divider/>
                                        }
                                    </Box>
                                )
                            })
                        }
                    </Box>
                ) : status === 'no_prescription' ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                        }}>
                        {attendance.last_dose ? (
                            <TypographyText>
                                {`Paciente teve sua ultima prescrição vencida dia ${format(attendance.time, 'DD/MM/YYYY')} às ${format(attendance.time, 'HH:mm')}.`}
                            </TypographyText>
                        ) : (
                            <TypographyText>
                                {`Paciente atendido dia ${format(attendance.time, 'DD/MM/YYYY')} às ${format(attendance.time, 'HH:mm')} e ainda não possui prescrição.`}
                            </TypographyText>
                        )}
                        {/*<Timer*/}
                        {/*    sx={{*/}
                        {/*        paddingLeft: '5px',*/}
                        {/*    }}>*/}
                        {/*    {attendance.time}*/}
                        {/*</Timer>*/}
                        {/*<TypographyText>*/}
                        {/*    {`.`}*/}
                        {/*</TypographyText>*/}
                    </Box>
                ) : status === 'discharged' ? (
                    <>
                        <TypographyText>
                            {`Paciente recebeu alta às ${format(attendance.time, 'HH:mm')} do dia ${format(attendance.time, 'DD/MM/YYYY')} por motivo ${attendance.released}`}
                        </TypographyText>
                    </>
                ) : status === 'dischargedLate' ? (
                    <>
                        <TypographyText>
                            {`Paciente recebeu alta às ${format(attendance.time, 'HH:mm')} do dia ${format(attendance.time, 'DD/MM/YYYY')} por motivo ${attendance.released}. Com Pendência para o dia:`}
                        </TypographyText>
                        {
                            attendance.late_events.map((events: any) => {
                                let times: any = []
                                events.times.map((time: any) => {
                                    times.push(
                                        <Box
                                            sx={{
                                                marginLeft: '15px'
                                            }}>
                                            <TypographyText>
                                                {`${format(time.time, 'HH:mm')} - ${time.name}`}
                                            </TypographyText>
                                        </Box>
                                    )
                                })
                                return (
                                    <Box>
                                        <TypographyText>
                                            {`${format(events.time, 'DD/MM/YYYY')}`}
                                        </TypographyText>
                                        {times}
                                    </Box>
                                )
                            })
                        }
                    </>
                ) : status === 'removed' ? (
                    <>
                        <TypographyText>
                            {`${format(attendance.time, 'DD/MM/YYYY')} ${format(attendance.time, 'HH:mm')}`}
                        </TypographyText>
                        <TypographyText>
                            {`${attendance.removed.assistant_doctor}`}
                        </TypographyText>
                        <TypographyText>
                            {`${attendance.re_tipo}`}
                        </TypographyText>
                    </>
                ) : status === 'late' ? (
                    <>
                        {
                            attendance.late_events.map((events: any) => {
                                let times: any = []
                                events.times.map((time: any) => {
                                    times.push(
                                        <Box
                                            sx={{
                                                marginLeft: '15px'
                                            }}>
                                            <TypographyText>
                                                {`${format(time.time, 'HH:mm')} - ${time.name}`}
                                            </TypographyText>
                                        </Box>
                                    )
                                })
                                return (
                                    <Box>
                                        <TypographyText>
                                            {`${format(events.time, 'DD/MM/YYYY')}`}
                                        </TypographyText>
                                        {times}
                                    </Box>
                                )
                            })
                        }
                    </>
                ) : status === 'done' ? (
                    <>
                        {
                            attendance.today_events.map((events: any) => {
                                let times: any = []
                                events.times.map((time: any) => {
                                    times.push(
                                        <Box
                                            sx={{
                                                marginLeft: '15px'
                                            }}>
                                            <TypographyText>
                                                {`${format(time.time, 'HH:mm')} - ${time.name}`}
                                            </TypographyText>
                                        </Box>
                                    )
                                })
                                return (
                                    <Box>
                                        <TypographyText>
                                            {`${format(events.time, 'DD/MM/YYYY')}`}
                                        </TypographyText>
                                        {times}
                                    </Box>
                                )
                            })
                        }
                    </>
                ) : status === 'waiting' ? (
                    <>
                        {
                            attendance.next_events.map((events: any) => {
                                let times: any = []
                                events.times.map((time: any) => {
                                    times.push(
                                        <Box
                                            sx={{
                                                marginLeft: '15px'
                                            }}>
                                            <TypographyText>
                                                {`${format(time.time, 'HH:mm')} - ${time.name}`}
                                            </TypographyText>
                                        </Box>
                                    )
                                })
                                return (
                                    <Box>
                                        <TypographyText>
                                            {`${format(events.time, 'DD/MM/YYYY')}`}
                                        </TypographyText>
                                        {times}
                                    </Box>
                                )
                            })
                        }
                    </>
                ) : status === 'tele' ? (
                    <>
                        <TypographyText>
                            {`${attendance?.tele?.name ? 'Início do Atendimento:' : 'Solicitado Em:'} ${format(attendance.tele.time, 'DD/MM/YYYY HH:mm')}`}
                        </TypographyText>
                        <TypographyText>
                            {`Queixa: ${attendance.tele.reason}`}
                        </TypographyText>
                        {attendance?.tele?.name ? (
                            <TypographyText>
                                {`${attendance.tele.function}: ${attendance.tele.name}`}
                            </TypographyText>
                        ) : (<></>)}
                    </>
                ) : (
                    <>
                    </>
                )
            }
            <Divider/>
        </Box>
    );
};

export default MarkerWindows;
