import React from "react";
import {Box} from "@mui/material";

interface IPropsSvg {
    fill: any;
    stroke?: any;
    width?: any;
    height?: any;
    sx?: any;
}

export default function THCSIconCheck(props: IPropsSvg) {
    const {fill, width, height, sx} = props;

    return (
        <Box sx={{fill: fill, width: width, height: height, ...sx}}>
            <svg
                width={width ? width : "31"}
                height={height ? height : "31"}
                viewBox={`0 0 31 31`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M15.0038 30.9999C12.0366 31.0006 9.13581 30.1213 6.66841 28.4732C4.20101 26.8251 2.27783 24.4823 1.14216 21.741C0.00649285 18.9997 -0.290646 15.9832 0.288332 13.073C0.86731 10.1628 2.29639 7.48975 4.3948 5.39189C7.20822 2.57834 11.0241 0.997652 15.003 0.997559C18.9818 0.997465 22.7978 2.57797 25.6113 5.39139C28.4249 8.20481 30.0055 12.0207 30.0056 15.9995C30.0057 19.9784 28.4252 23.7943 25.6118 26.6079C24.2219 28.0051 22.5687 29.1128 20.7478 29.8667C18.9269 30.6206 16.9746 31.0057 15.0038 30.9999ZM8.8178 15.7179C8.78038 15.7185 8.74344 15.7265 8.70912 15.7414C8.6748 15.7564 8.64376 15.7779 8.6178 15.8049L7.3298 17.0879C7.30259 17.1149 7.28099 17.1469 7.26625 17.1823C7.25151 17.2177 7.24392 17.2556 7.24392 17.2939C7.24392 17.3322 7.25151 17.3701 7.26625 17.4055C7.28099 17.4408 7.30259 17.4729 7.3298 17.4999L11.3708 21.5379C11.5887 21.7787 11.8909 21.9263 12.2148 21.9499C12.5318 21.92 12.8278 21.7782 13.0498 21.5499H13.0578L22.6998 11.8599C22.7493 11.802 22.7758 11.7279 22.7741 11.6517C22.7725 11.5755 22.7428 11.5026 22.6908 11.4469L21.4208 10.1419C21.3952 10.1145 21.3642 10.0926 21.3298 10.0776C21.2954 10.0627 21.2583 10.0549 21.2208 10.0549C21.1832 10.0542 21.1459 10.0617 21.1114 10.0767C21.0769 10.0917 21.046 10.1139 21.0208 10.1419L12.2208 19.0039L9.0208 15.8039C8.96691 15.7499 8.89409 15.719 8.8178 15.7179Z" fill={fill}/>
            </svg>
        </Box>
    );
}
