import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {keyframes} from '@emotion/react'
import THCSHouse1 from "../Icons/THCSHouse1";
import THCSHouse2 from "../Icons/THCSHouse2";
import THCSHouse3 from "../Icons/THCSHouse3";
import THCSHouse4 from "../Icons/THCSHouse4";
import THCSCloud from "../Icons/THCSCloud";
import {GlobalContext} from "../../contexts/GlobalContext";

export const Houses: React.FC<any> = () => {
    const [load, setLoad] = useState(true)

    const {
        configChecks,
    } = React.useContext(GlobalContext);

    const movingRightTop = keyframes({
        from: {
            left: '0%',
            opacity: 1,
        },
        to: {
            left: '100%',
            opacity: 1,
        }
    })

    const movingRightBottom = keyframes({
        from: {
            left: '-100%',
            opacity: 1,
        },
        to: {
            left: '0%',
            opacity: 1,
        }
    })

    useEffect(() => {
        setTimeout(() => {
            setLoad(!load)
        }, 1000)
    }, [load])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                flexWrap: 'nowrap',
                alignContent: 'flex-end',
                justifyContent: 'center',
                height: 'fit-content',
            }}
        >
            <THCSCloud fill={'#F4F7FF'} height={'10px'} width={'30px'}
                       sx={{
                           position: 'relative',
                           bottom: '55px',
                           left: '50%',
                           width: '0px',
                           zIndex: '1',
                           animation: configChecks.animations.value && `${movingRightTop} 7s linear infinite`,
                       }}/>
            <THCSHouse1 colorBody={'#4949F3'} colorWindow1={'#7EEDB1'} colorWindow2={'#7EEDB1'} height={'40px'} width={'25px'}
                        sx={{position: 'relative', left: '7px', zIndex: '10'}}/>
            <THCSHouse2 colorBody={'#7EEDB1'} colorDoor={'#4949F3'} height={'28px'} width={'25px'}
                        sx={{position: 'relative', left: '2px', zIndex: '10'}}/>
            <THCSHouse3 colorBody={'#F4F7FF'} colorWindow1={'#4949F3'} colorWindow2={'#4949F3'} height={'40px'} width={'25px'}
                        sx={{position: 'relative', right: '2px', zIndex: '10'}}/>
            <THCSHouse4 colorBody={'#4949F3'} colorDoor={'#000083'} height={'28px'} width={'25px'}
                        sx={{position: 'relative', right: '6px', zIndex: '10'}}/>
            <THCSCloud fill={'#F9F9F9'} height={'10px'} width={'30px'}
                       sx={{
                           position: 'relative',
                           bottom: '42px',
                           left: '-100%',
                           width: '0px',
                           zIndex: '1',
                           animation: configChecks.animations.value && `${movingRightBottom} 10s linear infinite`,
                       }}/>
        </Box>
    )
}

export default Houses;