import {Navigate, Route, Routes} from "react-router-dom"
import MapPage from "./pages/MapPage";
import NotFound from "./pages/NotFound";
import RequireAuth from "./components/Auth/RequireAuth";
import TokenPage from "./pages/TokenPage";
import LogIn from "./components/Login";

function AppRoutes() {
    return (
        <>
            <Routes>
                <Route path="/map" element={
                    <RequireAuth>
                        <MapPage/>
                    </RequireAuth>
                }/>
                <Route path="/" element={<Navigate to={"/map"} replace={true}/>}/>
                <Route path="/hash/:hash" element={<TokenPage/>}/>
                <Route path="/login" element={<LogIn/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </>
    )
}

export default AppRoutes