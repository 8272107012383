import React from "react";
import {Box} from "@mui/material";

interface IPropsSvg {
    colorBody?: any;
    colorDoor?: any;
    width?: any;
    height?: any;
    sx?: any;
}

export default function THCSHouse2(props: IPropsSvg) {
    const {colorBody, colorDoor, width, height, sx} = props;

    return (
        <Box sx={{colorBody: colorBody, colorDoor: colorDoor, width: width, height: height, ...sx}}>
            <svg
                width={width ? width : "101"}
                height={height ? height : "126"}
                viewBox={`0 0 100 126`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M101 14.5V125H0V14.5L50.5 0L101 14.5Z" fill={colorBody ? colorBody : "#7EEDB1"}/>
                <path d="M37 125.5H66V63.5L51.5 59L37 63.5V125.5Z" fill={colorDoor ? colorDoor : "#4949F3"}/>
            </svg>
        </Box>
    );
}
