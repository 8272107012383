import {
    Box,
    Checkbox,
    FormControlLabel, styled, Switch, Tooltip, tooltipClasses, TooltipProps, Typography, Zoom,
} from "@mui/material";
import React from "react";
import {ICheckBox} from "../../models/Checks/types";
import _ from "lodash";
import handleText from "../../utils/text";
import TypographyText from "../Text";
import Image from "../../assets/img/bgTexture.png";


const SwitchTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '2px solid #dadde9',
        borderRadius: '15px 15px 15px 15px',
        borderColor: theme.palette.primary.main,
        padding: '10px 25px 10px 25px',
        backgroundImage: `url(${Image})`,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.primary.main,
    },
}));

const SwitchTheme = styled(Switch)(({theme}) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#F6E58D',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#000083',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#4949FE',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#F6E58D',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));
const SwitchAnimation = styled(Switch)(({theme}) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',

        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M16.874 12.0678L16.8745 12.0681C17.8953 12.5503 18.5 13.5122 18.5 14.542V19.8C18.5 20.6985 17.6918 21.5 16.625 21.5H15.9375V11.659C16.2636 11.7902 16.5774 11.9273 16.874 12.0678ZM2.12545 12.0681L2.1261 12.0678C4.06584 11.148 6.58166 10.4 9.5 10.4C10.4916 10.4 11.43 10.4826 12.3156 10.6311L9.59651 16H6.53125C3.99996 16 1.875 17.9155 1.875 20.35C1.875 20.7478 1.93133 21.1339 2.03889 21.5H0.5V14.542C0.5 13.5122 1.10471 12.5503 2.12545 12.0681ZM5.25 4.4C5.25 2.28049 7.11504 0.5 9.5 0.5C11.885 0.5 13.75 2.28049 13.75 4.4C13.75 6.51951 11.885 8.3 9.5 8.3C7.11504 8.3 5.25 6.51951 5.25 4.4ZM6.53125 19.2H7.985L6.81801 21.5H6.53125C5.78504 21.5 5.25 20.9515 5.25 20.35C5.25 19.7485 5.78504 19.2 6.53125 19.2Z"/></svg>')`,
                animationName: 'ripple_out',
                animationDuration: '3s',
                animationDelay: '0s',
                animationIterationCount: 'infinite',
                animationTimingFunction: 'cubic-bezier(.65, 0, .34, 1)',

                '@keyframes ripple_out': {
                    from: {
                        // opacity: 1,
                        transform: 'scale3d(0.4, 0.4, 1)',
                        // background: 'rgba(153, 153, 153, 0.5)',
                    },

                    to: {
                        // opacity: 0,
                        transform: 'scale3d(1.0, 1.0, 1)',
                    }
                }
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#000083',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#4949FE',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M16.874 12.0678L16.8745 12.0681C17.8953 12.5503 18.5 13.5122 18.5 14.542V19.8C18.5 20.6985 17.6918 21.5 16.625 21.5H15.9375V11.659C16.2636 11.7902 16.5774 11.9273 16.874 12.0678ZM2.12545 12.0681L2.1261 12.0678C4.06584 11.148 6.58166 10.4 9.5 10.4C10.4916 10.4 11.43 10.4826 12.3156 10.6311L9.59651 16H6.53125C3.99996 16 1.875 17.9155 1.875 20.35C1.875 20.7478 1.93133 21.1339 2.03889 21.5H0.5V14.542C0.5 13.5122 1.10471 12.5503 2.12545 12.0681ZM5.25 4.4C5.25 2.28049 7.11504 0.5 9.5 0.5C11.885 0.5 13.75 2.28049 13.75 4.4C13.75 6.51951 11.885 8.3 9.5 8.3C7.11504 8.3 5.25 6.51951 5.25 4.4ZM6.53125 19.2H7.985L6.81801 21.5H6.53125C5.78504 21.5 5.25 20.9515 5.25 20.35C5.25 19.7485 5.78504 19.2 6.53125 19.2Z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

const SwitchNotification = styled(Switch)(({theme}) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',

        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 1 10 11"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M5 0.5L9.6375 3.275C9.77917 3.35 9.875 3.45208 9.925 3.58125C9.975 3.71042 10 3.8375 10 3.9625V9.75C10 9.95 9.925 10.125 9.775 10.275C9.625 10.425 9.45 10.5 9.25 10.5H0.75C0.55 10.5 0.375 10.425 0.225 10.275C0.075 10.125 0 9.95 0 9.75V3.9625C0 3.8375 0.0270833 3.71042 0.08125 3.58125C0.135417 3.45208 0.229167 3.35 0.3625 3.275L5 0.5ZM5 6.325L9.2 3.8625L5 1.3375L0.8 3.8625L5 6.325ZM5 7.1625L0.75 4.6625V9.75H9.25V4.6625L5 7.1625ZM5 9.75H9.25H0.75H5Z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#000083',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#4949FE',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 -1 11 11"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M0.75 8C0.55 8 0.375 7.925 0.225 7.775C0.075 7.625 0 7.45 0 7.25V0.75C0 0.55 0.075 0.375 0.225 0.225C0.375 0.0749999 0.55 0 0.75 0H9.25C9.45 0 9.625 0.0749999 9.775 0.225C9.925 0.375 10 0.55 10 0.75V7.25C10 7.45 9.925 7.625 9.775 7.775C9.625 7.925 9.45 8 9.25 8H0.75ZM5 4.225L0.75 1.4375V7.25H9.25V1.4375L5 4.225ZM5 3.475L9.2 0.75H0.8125L5 3.475ZM0.75 1.4375V0.75V1.4375V7.25V1.4375Z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

const CheckBoxComponent: React.FC<ICheckBox> = ({children, state, setState}: ICheckBox) => {
    const handleChangeChild = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            [event.target.name]: {
                value: event.target.checked,
                title: event.target.id,
                type: event.target.value,
            },
        });
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', ml: 3}}>
            <>
                {
                    _.map(state, (obj, key) => {
                        return (
                            <FormControlLabel
                                key={key}
                                label={
                                    // obj.title.length < 18 ? (
                                    obj.title.length < 18 ? (
                                        <TypographyText noWrap={true}>
                                            {obj.title}
                                        </TypographyText>
                                    ) : (
                                        <SwitchTooltip TransitionComponent={Zoom} title={
                                            <TypographyText fontSize={12}>
                                                {obj.title}
                                            </TypographyText>
                                        }
                                                       arrow>
                                            {/*TODO verifiacr pq não funciona no componente TypographyText*/}
                                            <Typography noWrap={true}>
                                                {
                                                    _.truncate(handleText(obj.title), {
                                                        length: 18,
                                                        omission: '...',
                                                    })
                                                }
                                            </Typography>
                                        </SwitchTooltip>
                                    )
                                }
                                control={
                                    obj.type === 'CheckFilter' ? (
                                        <Checkbox
                                            checked={Boolean(obj.value)}
                                            onChange={handleChangeChild}
                                            name={key}
                                            id={obj.title}
                                            value={obj.type}
                                            sx={{color: '#000083 !important'}}
                                        />
                                    ) : obj.type === 'SwitchAnimation' ? (
                                        <SwitchAnimation
                                            checked={Boolean(obj.value)}
                                            onChange={handleChangeChild}
                                            name={key}
                                            id={obj.title}
                                            value={obj.type}
                                        />
                                    ) : obj.type === 'SwitchTheme' ? (
                                        <SwitchTheme
                                            checked={Boolean(obj.value)}
                                            onChange={handleChangeChild}
                                            name={key}
                                            id={obj.title}
                                            value={obj.type}
                                        />
                                    ) : obj.type === 'SwitchNotification' ? (
                                        <SwitchNotification
                                            checked={Boolean(obj.value)}
                                            onChange={handleChangeChild}
                                            name={key}
                                            id={obj.title}
                                            value={obj.type}
                                        />
                                    ) : (
                                        <></>
                                    )


                                    // <Switch
                                    //     checked={Boolean(obj.value)}
                                    //     onChange={handleChangeChild}
                                    //     name={key}
                                    //     id={obj.title}
                                    //     defaultChecked
                                    // />
                                    // <MaterialUISwitch sx={{ m: 1 }} defaultChecked />
                                }
                                sx={{
                                    height: obj.type === 'SwitchAnimation' ?
                                        '60px' : obj.type === 'SwitchTheme' ?
                                            '60px' : obj.type === 'SwitchNotification' ?
                                                '60px' : 'auto'
                                }}
                            />
                        )
                    })
                }
            </>
        </Box>
    );
};

export default CheckBoxComponent;
