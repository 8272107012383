import React from "react";
import {Typography, useTheme} from "@mui/material";
import handleText from "../../utils/text";

interface TypographyText {
    children: any
    sx?: any
    variant?: any
    align?: any
    color?: any
    fontSize?: any
    noWrap?: any
}

const TypographyText: React.FC<TypographyText> = ({
                                                      children,
                                                      sx,
                                                      variant,
                                                      align,
                                                      color,
                                                      fontSize,
                                                      noWrap
                                                  }: TypographyText) => {
    const theme = useTheme();
    return (
        <Typography
            variant={variant}
            align={align}
            color={color}
            fontSize={fontSize}
            noWrap={noWrap}
            sx={{...sx}}
        >
            {handleText(children)}
        </Typography>
    );
};

export default TypographyText;
