import React from "react";
import {Box} from "@mui/material";

interface IPropsSvg {
    fill: any;
    width?: any;
    height?: any;
    sx?: any;
    boxWidth?: any;
}

export default function THCSLeftArrow(props: IPropsSvg) {
    const {fill, width, height, sx, boxWidth} = props;

    return (
        <Box sx={{fill: fill, width: width, height: height, ...sx}}>
            <svg
                width={width ? width : "47"}
                height={height ? height : "68"}
                viewBox={`0 0 ${boxWidth ? boxWidth : "47"} 68`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M9.94559 67.1296L0.134345 33.4326L9.67472 0.153461L45.9552 -1.00248e-05L36.4148 33.2791L46.2261 66.9762L9.94559 67.1296Z" fill={fill}/>
            </svg>
        </Box>
    );
}
