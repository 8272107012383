import React, {createContext, useState} from "react";
import {AreaOptions, FilterOptions, IGlobalContext, IGlobalProvider, ConfigOptions} from "../models/Global/types";
import {ICheckBoxState} from "../models/Checks/types";
import useStateWithLocalStorage from "../hooks/useStateWithLocalStorage";


export const GlobalContext = createContext<IGlobalContext>(
    {} as IGlobalContext
);

export const GlobalProvider = ({children}: IGlobalProvider) => {
    // Configurações
    const [configChecks, setConfigChecks] = useStateWithLocalStorage<ICheckBoxState>('THCS-MAP-ConfigOptions', ConfigOptions);

    // Status
    const [filterChecks, setFilterChecks] = useStateWithLocalStorage<ICheckBoxState>('THCS-MAP-StatusFilter', FilterOptions);

    // Area
    const [areaChecks, setAreaChecks] = useStateWithLocalStorage<ICheckBoxState>('THCS-MAP-AreaFilter', AreaOptions);

    const [expandedTopBar, setExpandedTopBar] = React.useState<string | false>(false);
    // const [center, setCenter] = React.useState({lat: 2, lng: -27}); // TODO ACTIVATE
    const [center, setCenter] = React.useState({lat: -8.076, lng: -34.918});
    const [markerCenter, setMarkerCenter] = React.useState({lat: 2, lng: -27});
    const [zoom, setZoom] = React.useState(12);
    const [mapRef, setMapRef] = React.useState<any>(null);
    const [activeMarker, setActiveMarker] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState<any>(null);

    const markerClickHandler = (event: any, attendance: any, position: any) => {
        if(!attendance) return;

        if (activeMarker === attendance.attendance_id) {
            setActiveMarker(null)
            return;
        }

        // Remember which place was clicked
        setSelectedPlace(attendance);
        setActiveMarker(attendance.attendance_id);

        // // // Required so clicking a 2nd marker works as expected
        // if (infoOpen) {
        //     setInfoOpen(false);
        // }
        //
        // setInfoOpen(true);
        // If you want to zoom in a little on marker click
        if (mapRef.zoom < 13) {
            setZoom(13);
        }

        // if you want to center the selected Marker
        setCenter(position)
    };

    return (
        <GlobalContext.Provider
            value={{
                filterChecks,
                setFilterChecks,
                areaChecks,
                setAreaChecks,
                configChecks,
                setConfigChecks,
                expandedTopBar,
                setExpandedTopBar,
                center,
                setCenter,
                markerCenter,
                setMarkerCenter,
                zoom,
                setZoom,
                mapRef,
                setMapRef,
                activeMarker,
                setActiveMarker,
                selectedPlace,
                setSelectedPlace,
                markerClickHandler,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};
