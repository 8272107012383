import {Navigate} from "react-router-dom";

interface RequireAuthProps {
    children: JSX.Element;
}

function RequireAuth({children}: RequireAuthProps) {
    const access_token = 'asd'

    if (!access_token) {
        return <Navigate to={"/login"} replace={true}/>;
    }
    return children;
}

export default RequireAuth;
