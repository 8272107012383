import React from "react";
import { Alert, Snackbar } from "@mui/material";
import Slide, { SlideProps } from "@mui/material/Slide";

type TransitionProps = Omit<SlideProps, "direction">;

export interface INotifierProps {
    msg: string;
    severity: "error" | "success" | "warning" | "info";
    closeTime?: number;
    closeState?: React.Dispatch<React.SetStateAction<INotifierProps>>;
    open: boolean;
    anchorOrigin?: any;
    slideDirection?: 'left' | 'right'
}
function Notifier({
                      msg,
                      open,
                      severity,
                      closeTime = 3000,
                      closeState,
                      slideDirection,
                      ...props
                  }: INotifierProps) {
    function handleClose() {
        // @ts-ignore
        closeState(null);
    }
    function TransitionRight(props: TransitionProps) {
        return <Slide {...props} direction="left" />;
    }
    return (
        <Snackbar
            open={open}
            autoHideDuration={closeTime}
            onClose={handleClose}
            TransitionComponent={TransitionRight}
            {...props}
        >
            <Alert severity={severity}>{msg}</Alert>
        </Snackbar>
    );
}

export default React.memo(Notifier);
