import {useQuery, UseQueryResult} from "react-query";
import instance from "../core/api";
import _mock_attendance from "../_mocks/attendence";

function useEvents() {
    const getEvents: UseQueryResult<any, any> = useQuery(
        'events',
        async () => {
            const now = new Date().toISOString().split('T')[0]
            // return _mock_attendance() // TODO REMOVE MOCK
            return instance.get(`/api/geolocations/map?date=${now}`).then((res) => res.data)
        },
        {
            refetchInterval: 15000,
        }
    )

    return {
        getEvents
    }
}

export default useEvents;