import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import {GlobalContext} from "../../../contexts/GlobalContext";

interface IZoomButton {

}

export default function ZoomButton({}: IZoomButton) {
    const {
        mapRef,
        setCenter,
        setZoom,
        markerCenter,
    } = React.useContext(GlobalContext);

    const handleCenter = () => {
        setCenter(markerCenter)
        mapRef.setCenter(markerCenter)
    }

    const handleZoomIn = () => {
        const zoom = mapRef.getZoom() + 1
        setZoom(zoom)
        mapRef.setZoom(zoom)
    }

    const handleZoomOut = () => {
        const zoom = mapRef.getZoom() - 1
        setZoom(zoom)
        mapRef.setZoom(zoom)
    }

    return (
        <Box
            sx={{
                position: 'absolute',
                bottom: '35px',
                right: '8px',
                display: 'flex',
                '& > *': {
                    m: 1,
                },
                flexDirection: 'column',
            }}
        >
            <ButtonGroup
                orientation="horizontal"
                aria-label="vertical contained button group"
                variant="contained"
                sx={{
                    margin: '0px',
                }}
            >
                <Button key="+"
                        sx={{
                            borderRadius: '20px 0px 0px 0px'
                        }}
                        onClick={handleZoomIn}
                >+</Button>
                <Button key="-"
                        sx={{
                            borderRadius: '0px 0px 0px 0px'
                        }}
                        onClick={handleZoomOut}
                >-</Button>
            </ButtonGroup>
            <ButtonGroup
                orientation="vertical"
                aria-label="vertical contained button group"
                variant="contained"
                sx={{
                    margin: '0px',
                }}
            >
                <Button key="Centro"
                        sx={{
                            borderRadius: '0px 0px 10px 10px'
                        }}
                        onClick={handleCenter}
                >Centro</Button>
            </ButtonGroup>
        </Box>
    );
}