import React from "react";
import {Box} from "@mui/material";

interface IPropsSvg {
    fill: any;
    stroke?: any;
    width?: any;
    height?: any;
    sx?: any;
}

export default function THCSIconDefault(props: IPropsSvg) {
    const {fill, width, height, sx} = props;

    return (
        <Box sx={{fill: fill, width: width, height: height, ...sx}}>
            <svg
                width={width ? width : "30"}
                height={height ? height : "30"}
                viewBox={`0 0 30 30`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="15" cy="15" r="10" fill={fill}/>
                <path d="M13.0364 19.3335H13.5573V15.5211H13.0364C12.7489 15.5211 12.5157 15.2879 12.5157 15.0002V13.7346C12.5157 13.4469 12.7489 13.2137 13.0364 13.2137H15.9897C16.1278 13.2137 16.2603 13.2687 16.358 13.3663C16.4556 13.464 16.5104 13.5965 16.5104 13.7346V19.3333H17.0313C17.1694 19.3333 17.3019 19.3882 17.3996 19.4859C17.4972 19.5835 17.5522 19.7161 17.5522 19.8542V21.1459C17.5522 21.284 17.4972 21.4165 17.3996 21.5142C17.3019 21.6119 17.1694 21.6668 17.0313 21.6668H13.0209C12.7332 21.6668 12.5 21.4335 12.5 21.1459V19.8595C12.4986 19.7177 12.555 19.5815 12.6561 19.4821C12.7574 19.3829 12.8948 19.3292 13.0364 19.3333L13.0364 19.3335ZM15.0417 8.125C14.5372 8.125 14.0535 8.32554 13.6969 8.6825C13.3402 9.03929 13.1402 9.52339 13.1407 10.0279C13.1411 10.5324 13.3422 11.016 13.6993 11.3722C14.0566 11.7283 14.5407 11.928 15.0452 11.9271C15.5497 11.9263 16.0331 11.7249 16.389 11.3672C16.745 11.0097 16.9441 10.5254 16.9427 10.0209C16.9413 9.51768 16.7404 9.03549 16.3841 8.68009C16.0277 8.32469 15.545 8.125 15.0417 8.125Z" fill="white"/>
            </svg>
        </Box>
    );
}
