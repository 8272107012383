import React, {ReactNode} from "react";
import {ICheckBoxState} from "../Checks/types";


export interface IGlobalContext {
    filterChecks: ICheckBoxState,
    setFilterChecks: React.Dispatch<React.SetStateAction<ICheckBoxState>>,
    areaChecks: ICheckBoxState,
    setAreaChecks: React.Dispatch<React.SetStateAction<ICheckBoxState>>,
    configChecks: ICheckBoxState,
    setConfigChecks: React.Dispatch<React.SetStateAction<ICheckBoxState>>,
    expandedTopBar: string | false,
    setExpandedTopBar: React.Dispatch<React.SetStateAction<string | false>>,
    center: { lat: number, lng: number },
    setCenter: React.Dispatch<React.SetStateAction<{ lat: number, lng: number }>>,
    markerCenter: { lat: number, lng: number },
    setMarkerCenter: React.Dispatch<React.SetStateAction<{ lat: number, lng: number }>>,
    zoom: number,
    setZoom: React.Dispatch<React.SetStateAction<number>>,
    mapRef: any,
    setMapRef: React.Dispatch<React.SetStateAction<any>>,
    activeMarker: any,
    setActiveMarker: React.Dispatch<React.SetStateAction<any>>,
    selectedPlace: any,
    setSelectedPlace: React.Dispatch<React.SetStateAction<any>>,
    markerClickHandler: any,
}

export interface IGlobalProvider {
    children: ReactNode;
}

export const FilterOptions: ICheckBoxState = {
    done: {
        value: true,
        title: 'Atendidos',
        type: 'CheckFilter'
    },
    late: {
        value: true,
        title: 'Atrasados',
        type: 'CheckFilter'
    },
    waiting: {
        value: true,
        title: 'Eventos Futuros',
        type: 'CheckFilter'
    },
    attending: {
        value: true,
        title: 'Em Atendimento',
        type: 'CheckFilter'
    },
    discharged: {
        value: true,
        title: 'Em Alta',
        type: 'CheckFilter'
    },
    removed: {
        value: true,
        title: 'Atend. Fora Domic.',
        type: 'CheckFilter'
    },
    no_prescription: {
        value: true,
        title: 'Sem Prescrição',
        type: 'CheckFilter'
    },
    no_geo: {
        value: true,
        title: 'Sem Geolocalização',
        type: 'CheckFilter'
    },
    tele: {
        value: true,
        title: 'Telemedicina',
        type: 'CheckFilter'
    },
}

export const AreaOptions: ICheckBoxState = {}

export const ConfigOptions: ICheckBoxState = {
    animations: {
        value: true,
        title: 'Animação',
        type: 'SwitchAnimation',
    },
    theme: {
        value: true,
        title: 'Alterar Tema',
        type: 'SwitchTheme',
    },
    notifications: {
        value: true,
        title: 'Ativar Notificações',
        type: 'SwitchNotification',
    },
}