import React from 'react';
import defaultTheme from "./theme/theme";
import {ThemeProvider} from "@mui/material";

import {
    createTheme,
    Paper,
    CssBaseline,
} from "@mui/material";
import TopBar from "./components/Bar/TopBar";
import BottomBar from "./components/Bar/BottomBar";
import {GlobalProvider} from "./contexts/GlobalContext";
import {ReactQueryDevtools} from 'react-query/devtools'
import {QueryClient, QueryClientProvider} from "react-query";
import MapView from "./components/Map";
import {Routes, Route, Link, BrowserRouter} from "react-router-dom";
import "./fonts/css/general-sans.css";
import Map from "./pages/MapPage";
import MapPage from "./pages/MapPage";
import AppRoutes from "./Routes";
import {ToastContainer} from "react-toastify";

function App() {
    const queryClient = new QueryClient()

    // const {
    //     configChecks,
    // } = React.useContext(GlobalContext);
    //
    // const [mode, setMode] = React.useState<'light' | 'dark'>('light');

    const Theme = createTheme(defaultTheme('light'));

    return (
        <ThemeProvider theme={Theme}>
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <GlobalProvider>
                        <CssBaseline/>
                        <Paper>
                            <AppRoutes/>
                        </Paper>
                        {/*<ToastContainer/>*/}
                        <ReactQueryDevtools initialIsOpen={false} position={'bottom-left'}/>
                    </GlobalProvider>
                </QueryClientProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
