import React from "react";
import {Box} from "@mui/material";

interface IPropsSvg {
    fill: any;
    width?: any;
    height?: any;
    sx?: any;
    color1?: any;
    color2?: any;
    color3?: any;
}

export default function THCSButtonLeft(props: IPropsSvg) {
    const {fill, color1, color2, color3, width, height, sx} = props;

    return (
        <Box sx={{...sx, fill: fill, width: width, height: height}}>
            <svg
                width={width ? width : "245"}
                height={height ? height : "132"}
                viewBox="0 0 245 132"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M170 0L150 66.4925L170 132H245L225 66.4925L245 0H170Z" fill={color1 ? color1 : "#000083"}/>
                <path d="M95 0L75 66.4925L95 132H170L150 66.4925L170 0H95Z" fill={color2 ? color2 : "#F4F7FF"}/>
                <path d="M20 0L0 66.4925L20 132H95L75 66.4925L95 0H20Z" fill={color3 ? color3 : "#7EEDB1"}/>
            </svg>
        </Box>
    );
}
