import React, {useEffect, useState} from "react";
import {
    Box,
    Divider, Grow,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText, Paper,
    Slide, styled, Tooltip, tooltipClasses, TooltipProps,
    Typography, useTheme, Zoom
} from "@mui/material";
import THCSIconFace from "../../Icons/THCSIconFace";
import useEvents from "../../../services/useEvents";
import ButtonLeft from "../../Button/ButtonLeft";
import handleStatus from "../../../utils/status";
import _ from "lodash";
import {GlobalContext} from "../../../contexts/GlobalContext";
import handleText from "../../../utils/text";
import getDuration from "../../../utils/date";
import format from "../../../utils/formatDate";
import THCSIconHouse from "../../Icons/THCSIconHouse";
import THCSIconHours from "../../Icons/THCSIconHours";
import THCSIconDefault from "../../Icons/THCSIconDefault";
import THCSIconUser from "../../Icons/THCSIconUser";
import Image from "../../../assets/img/bgTexture.png";
import TypographyText from "../../Text";
import THCSIconUserInjured from "../../Icons/THCSIconUserInjured";
import PatientSub from "../../Text/PatientSub";
import Timer from "../../Text/Timer";
import PatientSubCard from "../PatientSubCard";


interface IFilter {
    onClick: any
}

const SubTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '2px solid #dadde9',
        borderRadius: '15px 15px 15px 15px',
        borderColor: theme.palette.primary.main,
        padding: '10px 25px 10px 25px',
        backgroundImage: `url(${Image})`,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.primary.main,
    },
}));

const PatientTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '2px solid #dadde9',
        borderRadius: '15px 15px 15px 15px',
        borderColor: '#ff6565',
        padding: '10px 25px 10px 25px',
        backgroundImage: `url(${Image})`,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#ff6565',
    },
}));

const CardList: React.FC<IFilter> = ({onClick}: IFilter) => {
    const theme = useTheme();

    const [openList, setOpenList] = useState(false)
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [state, setState] = React.useState<any>(undefined)
    const {getEvents} = useEvents();

    const {
        areaChecks,
        filterChecks,
        activeMarker,
    } = React.useContext(GlobalContext);

    useEffect(() => {
        if (getEvents.isSuccess) {
            setState(getEvents.data)
        }

    }, [getEvents.dataUpdatedAt])

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setOpenList(!openList)
        };

    useEffect(() => {
        if (!activeMarker) return setSelectedIndex(-1)

        setSelectedIndex(activeMarker)

    }, [activeMarker])

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
        item: any,
    ) => {
        if (item.status === 'no_geo') return

        const LatLng = new google.maps.LatLng(item.position.lat, item.position.lng)
        onClick(event, item, LatLng)
        if (item.attendance_id === selectedIndex) {
            setSelectedIndex(-1);
        } else {
            setSelectedIndex(item.attendance_id);
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            borderRadius: '10px',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            alignContent: 'center',
            flexWrap: 'nowrap',
            position: 'relative',
            zIndex: '1',
            width: 'fit-content',
            height: 'fit-content',
            top: '80px',
            left: '25px',
            backgroundColor: 'transparent'
        }}>

            <Grow
                in={!openList}
                style={{transformOrigin: '0 0 0'}}
                {...(!openList ? {timeout: 1000} : {})}
            >
                <IconButton size="large" aria-label="card-button" edge={'start'}
                            onClick={() => setOpenList(!openList)}
                            sx={{
                                padding: '0',
                                backgroundColor: 'transparent !important',
                            }}>
                    <THCSIconUserInjured fill={theme.palette.primary.main} height={'25px'} width={'25px'}
                                         sx={{marginLeft: '10px'}}/>
                </IconButton>
            </Grow>

            <Slide direction="right" in={openList} mountOnEnter unmountOnExit>
                <Box sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: 'background.paper',
                    position: 'relative',
                    bottom: '25px',
                    borderRadius: '10px 10px 10px 10px',
                    maxHeight: '75vh',
                }}>
                    <ButtonLeft name={handleText('Lista De Pacientes')} onClick={handleChange} position={'left'}>
                        <SubTooltip TransitionComponent={Zoom} placement="right-end" title={
                            <PatientSubCard/>
                        } arrow>
                            <IconButton>
                                <THCSIconDefault fill={'#999999'} width={'30px'} height={'30px'}/>
                            </IconButton>
                        </SubTooltip>
                    </ButtonLeft>
                    <Box sx={{
                        width: '100%',
                        maxWidth: 360,
                        bgcolor: 'background.paper',
                        position: 'relative',
                        borderRadius: '10px 10px 10px 10px',
                        overflowX: 'auto',
                        overflowY: 'unset',
                        maxHeight: '75vh',
                        padding: '0px',
                    }}>
                        <List component="nav" aria-label="main mailbox folders"
                              sx={{
                                  backgroundColor: theme.palette.common.white,
                                  padding: '0px 5px 2px 5px',
                              }}
                        >
                            <Divider/>

                            {state?.map((item: any, index: number) => {
                                const {hex: color, hex_light: color_light} = handleStatus(item.status, theme)

                                // TODO REMOVE DUP CODE (PUT IN CONTEXT)
                                const activeAreas: any = []
                                const activeFilters: any = []

                                _.map(areaChecks, ((area, key) => {
                                    if (area.value) {
                                        activeAreas.push(area.title)
                                    }
                                }))

                                _.map(filterChecks, ((filter, key) => {
                                    if (filter.value) {
                                        activeFilters.push(key)
                                    }
                                }))

                                // if (item.status === 'dischargedLate') {
                                //     item.status = 'discharged'
                                // }

                                const status = item.status === 'dischargedLate' ? 'discharged' : item.status
                                const visible = _.includes(activeAreas, item.area) && (_.includes(activeFilters, status))

                                if (visible) {
                                    return (
                                        <PatientTooltip
                                            key={'CardList' + item.attendance_id}
                                            TransitionComponent={Zoom} placement="right" title={
                                            item.status === 'no_geo' ? (
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                }}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                    }}>
                                                        <TypographyText>{format(item.time, 'DD/MM/YY HH:mm')}</TypographyText>
                                                        <TypographyText>{handleText(item.assistant_doctor)}</TypographyText>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}>
                                                            <THCSIconHours width={'17px'} height={'17px'}
                                                                           fill={'#FB0909'}/>
                                                            <Timer
                                                                sx={{
                                                                    paddingLeft: '5px',
                                                                }}>
                                                                {item.time}
                                                            </Timer>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ) : ('')
                                        } arrow>
                                            <Paper>
                                                <ListItemButton
                                                    key={'PatientList' + item.attendance_id}
                                                    // disabled={item.status === 'no_geo'}
                                                    divider={true}
                                                    selected={selectedIndex === item.attendance_id}
                                                    onClick={(event) =>
                                                        handleListItemClick(event, index, item)
                                                    }
                                                    sx={{
                                                        backgroundColor: item.status === 'no_geo' ? theme.palette.error.light : 'transparent',
                                                        "&:hover": {
                                                            backgroundColor: item.status === 'no_geo' ? theme.palette.error.light : 'transparent',
                                                        },
                                                        cursor: item.status === 'no_geo' ? 'default' : 'pointer'
                                                        // backgroundImage: `url(${Image})`,
                                                    }}
                                                >
                                                    <ListItemIcon onClick={(e) => {
                                                        e.stopPropagation()

                                                        window.open(process.env.REACT_APP_HCS_HOST + `/care/${item.attendance_id}/redirect`, '_blank', 'noopener,noreferrer')
                                                    }}
                                                                  onMouseEnter={() => false}
                                                                  onMouseLeave={() => false}
                                                    >
                                                        <THCSIconUserInjured fill={color} height={'25px'}
                                                                             width={'25px'}
                                                                             sx={{cursor: 'pointer !important'}}/>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        disableTypography
                                                        primary={
                                                            <TypographyText
                                                                sx={{fontWeight: selectedIndex === item.attendance_id ? 'bold' : 'normal'}}>
                                                                {handleText(item.label)}
                                                            </TypographyText>}
                                                    />
                                                </ListItemButton>

                                            </Paper>
                                        </PatientTooltip>
                                    )
                                }
                            })}
                        </List>
                    </Box>
                </Box>
            </Slide>
        </Box>
    );
};

export default CardList;
