import {useQuery, UseQueryResult} from "react-query";
import instance from "../core/api";
import _mock_area from "../_mocks/area";

function useAreas() {
    const getAreas: UseQueryResult<any, any> = useQuery(
        'areas',
        async () => {
            // const now = new Date().toISOString().split('T')[0]
            // return _mock_area() // TODO REMOVE MOCK
            return instance.get(`/sector`).then((res) => res.data)
        },
        {
            refetchInterval: 24 * 60 * 60000,
        }
    )

    return {
        getAreas
    }
}

export default useAreas;