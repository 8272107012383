import React from "react";
import {Box, Collapse, useTheme} from "@mui/material";
import TypographyText from "../../Text";
import PatientSub from "../../Text/PatientSub";

interface IPatientSubCard {

}

const PatientSubCard: React.FC<IPatientSubCard> = ({}: IPatientSubCard) => {
    const theme = useTheme();

    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <TypographyText variant={'h6'}>Legenda</TypographyText>
                </Box>

                <PatientSub color={theme.palette.attending.main}>
                    Em Atendimento
                </PatientSub>
                <PatientSub color={theme.palette.late.main}>
                    Atrasados
                </PatientSub>
                <PatientSub color={theme.palette.discharged.main}>
                    Alta
                </PatientSub>
                <PatientSub color={theme.palette.removed.main}>
                    AFD
                </PatientSub>
                <PatientSub color={theme.palette.done.main}>
                    Sem Pendência
                </PatientSub>
                <PatientSub color={theme.palette.waiting.main}>
                    Pendência Futura
                </PatientSub>
                <PatientSub color={theme.palette.no_prescription.main}>
                    Sem Prescrição
                </PatientSub>
                <PatientSub color={theme.palette.tele.main}>
                    Telemedicina
                </PatientSub>
            </Box>
        </>
    )
}
export default PatientSubCard