import React from "react";
import {Box, Typography, useTheme} from "@mui/material";
import TypographyText from "../index";
import THCSIconUserInjured from "../../Icons/THCSIconUserInjured";

interface IPatientSub {
    children: any,
    color: any,
}

const PatientSub: React.FC<IPatientSub> = ({children, color}: IPatientSub) => {
    const theme = useTheme();
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px 0px 0px 0px'
        }}>
            <THCSIconUserInjured fill={color} width={'25px'} height={'25px'}/>
            <TypographyText
                sx={{paddingLeft: '10px'}}
            >
                {children}
            </TypographyText>
        </Box>
    );
};

export default PatientSub;
