import React from "react";
import _ from "lodash";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton, styled, Tooltip, tooltipClasses, TooltipProps,
    Typography,
    useTheme,
    Zoom
} from "@mui/material";
import {IGroupCheckBox} from "../../../models/Checks/types";
import CheckBoxComponent from "../index";
import Image from "../../../assets/img/bgTexture.png";
import ButtonLeft from "../../Button/ButtonLeft";
import handleText from "../../../utils/text";
import TypographyText from "../../Text";
import PatientSub from "../../Text/PatientSub";
import THCSIconDefault from "../../Icons/THCSIconDefault";
import PatientSubCard from "../../Card/PatientSubCard";

interface IGroup extends IGroupCheckBox {
    width?: any,
    handleChange?: any,
    name?: any,
}

const SubTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '2px solid #dadde9',
        borderRadius: '15px 15px 15px 15px',
        borderColor: theme.palette.primary.main,
        padding: '10px 25px 10px 25px',
        backgroundImage: `url(${Image})`,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.primary.main,
    },
}));

const GroupCheckBox: React.FC<IGroup> = ({children, state, setState, open, name, handleChange, width}: IGroup) => {
    const theme = useTheme();
    const handleChangeParent = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(() => {
            const arrayOfState = _.map(state, (obj, key) => {
                return {
                    [key]: {
                        value: event.target.checked,
                        title: obj.title,
                        type: obj.type,
                    }
                }
            })
            return Object.assign({}, ...arrayOfState)
        });
    };

    const arrayOfChecks = _.map(state, (obj) => {
        return obj.value
    })

    const someChecks = arrayOfChecks.includes(true)

    const allChecks = arrayOfChecks.every((v) => {
        return v
    })

    return (
        <>
            <Box sx={{
                maxHeight: open ? '500px' : '0px',
                visibility: open ? 'visible' : 'hidden',
                opacity: open ? 1 : 0,
                transition: '2s ease-in-out, max-height 1s cubic-bezier(0.4, 0, 1, 1)',
                zIndex: 0,
                width: width ? width : '240px',
                backgroundColor: theme.palette.common.white,
                backgroundImage: `url(${Image})`,
                borderRadius: '10px',
            }}
            >
                <ButtonLeft name={handleText(name)} onClick={handleChange} position={'right'}>
                    {
                        name === 'Status' && (
                            <SubTooltip TransitionComponent={Zoom} placement="right-end" title={
                                <PatientSubCard/>
                            } arrow>
                                <IconButton>
                                    <THCSIconDefault fill={'#999999'} width={'30px'} height={'30px'}/>
                                </IconButton>
                            </SubTooltip>
                        )
                    }
                </ButtonLeft>
                {
                    name === 'Notificações' ? (
                        <TypographyText align={'center'} sx={{
                            padding: '20px'
                        }}>
                            Não há mais notificações
                        </TypographyText>
                    ) : (
                        <FormControl sx={{
                            m: 3,
                            opacity: open ? 1 : 0,
                            transform: open ? 'translateY(0)' : 'translateY(-25%)',
                            transition: open ? '1.2s ease-in-out' : '0.5s ease-in-out',
                        }} component="fieldset"
                                     variant="standard"
                        >
                            <FormGroup>
                                {
                                    children && (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={allChecks}
                                                    indeterminate={someChecks && !allChecks}
                                                    onChange={handleChangeParent}
                                                    name="Filtros"
                                                    sx={{color: '#000083 !important'}}
                                                />
                                            }
                                            label={
                                                <TypographyText>
                                                    {handleText(String(children))}
                                                </TypographyText>
                                            }
                                            sx={{height: '25px'}}
                                        />
                                    )
                                }
                                <CheckBoxComponent state={state} setState={setState}/>
                            </FormGroup>
                        </FormControl>
                    )
                }
            </Box>
        </>
    );
};

export default GroupCheckBox;