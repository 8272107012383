import React from "react";
import {Box} from "@mui/material";

interface IPropsSvg {
    fill: any;
    width?: any;
    height?: any;
    sx?: any;
}

export default function THCSPoweredBy(props: IPropsSvg) {
    const {fill, width, height, sx} = props;

    return (
        <Box sx={{...sx, fill: fill, width: width, height: height}}>
            <svg
                width={width ? width : "553"}
                height={height ? height : "51"}
                viewBox={`0 0 553 51`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.2686 14.5036H3.62498V28.2532H10.2686C12.4874 28.2532 14.2495 27.6576 15.5681 26.441C16.874 25.2372 17.5333 23.5391 17.5333 21.3341C17.5333 19.1417 16.874 17.4563 15.5681 16.2778C14.2495 15.0992 12.4874 14.5036 10.2686 14.5036ZM10.3065 30.9651H3.62498V42.1038H0.506104V11.8044H10.3065C12.3604 11.8044 14.161 12.1719 15.7078 12.9196C17.2672 13.6673 18.4716 14.7444 19.3464 16.1511C20.2212 17.5577 20.6524 19.2811 20.6524 21.3341C20.6524 23.387 20.2212 25.1358 19.3464 26.5678C18.4716 27.9997 17.2672 29.0896 15.7078 29.8373C14.161 30.5849 12.3604 30.9651 10.3065 30.9651Z"
                    fill={fill}/>
                <path
                    d="M38.4023 39.9877C39.835 39.9877 41.1153 39.6202 42.2437 38.8726C43.3595 38.1249 44.247 37.0731 44.8936 35.7298C45.5529 34.3739 45.8826 32.7898 45.8826 30.965C45.8826 29.1275 45.5529 27.5561 44.8936 26.2382C44.247 24.9076 43.3595 23.8811 42.2437 23.1588C41.1153 22.4238 39.835 22.0563 38.4023 22.0563C36.9823 22.0563 35.7144 22.4238 34.5987 23.1588C33.4703 23.8811 32.5828 24.9076 31.9362 26.2382C31.2896 27.5561 30.9598 29.1275 30.9598 30.965C30.9598 32.7898 31.2896 34.3739 31.9362 35.7298C32.5828 37.0731 33.4703 38.1249 34.5987 38.8726C35.7144 39.6202 36.9823 39.9877 38.4023 39.9877V39.9877ZM38.4023 42.522C36.9062 42.522 35.5115 42.2432 34.2436 41.6732C32.9631 41.1156 31.8728 40.3172 30.9346 39.2781C30.009 38.2263 29.2863 37.0097 28.7792 35.6031C28.2594 34.1965 28.0059 32.6504 28.0059 30.965C28.0059 29.3049 28.2594 27.7715 28.7792 26.3649C29.2863 24.9583 30.009 23.7417 30.9346 22.7153C31.8728 21.6888 32.9631 20.8904 34.2436 20.3202C35.5115 19.7626 36.9062 19.4838 38.4023 19.4838C39.9237 19.4838 41.3183 19.7626 42.5988 20.3202C43.8667 20.8904 44.9697 21.6888 45.8953 22.7153C46.8208 23.7417 47.5434 24.9583 48.0633 26.3649C48.5704 27.7715 48.824 29.3049 48.824 30.965C48.824 32.6504 48.5704 34.1965 48.0633 35.6031C47.5434 37.0097 46.8208 38.2263 45.8953 39.2781C44.9697 40.3172 43.8667 41.1156 42.5988 41.6732C41.3183 42.2432 39.9237 42.522 38.4023 42.522"
                    fill={fill}/>
                <path
                    d="M76.0832 42.1038L70.3526 23.7925L64.6599 42.1038H61.7057L55.303 19.8641H58.1812L63.2397 37.9223L68.9324 19.8641H71.7977L77.4526 37.9603L82.524 19.8641H85.4272L78.9866 42.1038H76.0832Z"
                    fill={fill}/>
                <path
                    d="M102.137 42.522C100.083 42.522 98.2955 42.0408 96.7487 41.0649C95.2146 40.0891 94.0229 38.7205 93.1861 36.9464C92.3366 35.1722 91.9182 33.1193 91.9182 30.7876C91.9182 28.6206 92.3491 26.6944 93.224 24.9836C94.0988 23.2855 95.2907 21.9422 96.7995 20.9411C98.3082 19.94 100.032 19.4458 101.972 19.4458C103.519 19.4458 104.926 19.7372 106.182 20.3455C107.449 20.9538 108.514 21.8028 109.389 22.9053C110.251 23.9952 110.911 25.2877 111.342 26.7577C111.773 28.2404 111.912 29.8752 111.773 31.6746H93.4904V29.6471H108.743C108.628 27.1506 107.969 25.1991 106.765 23.8051C105.56 22.4111 103.95 21.7141 101.922 21.7141C100.514 21.7141 99.2717 22.0943 98.2067 22.842C97.1417 23.577 96.3048 24.6288 95.6963 25.9847C95.0877 27.328 94.7835 28.9374 94.7835 30.7876C94.7835 32.7011 95.0752 34.3739 95.6584 35.7932C96.2289 37.2125 97.0656 38.3023 98.1686 39.0627C99.259 39.823 100.565 40.2032 102.099 40.2032C103.925 40.2032 105.434 39.6963 106.638 38.6952C107.842 37.7067 108.628 36.3381 108.996 34.5893H111.861C111.354 37.0351 110.239 38.9739 108.515 40.3933C106.778 41.8126 104.66 42.522 102.137 42.522Z"
                    fill={fill}/>
                <path
                    d="M124.287 30.2048V42.1038H121.371V19.8641H124.071V24.9204C124.845 23.2983 125.986 21.9804 127.482 21.0046C128.978 20.0161 130.626 19.5219 132.427 19.5219V22.6013C130.905 22.5506 129.523 22.8167 128.294 23.4124C127.064 23.9953 126.087 24.8697 125.365 26.0229C124.642 27.1761 124.287 28.57 124.287 30.2048"
                    fill={fill}/>
                <path
                    d="M149.34 42.522C147.286 42.522 145.498 42.0408 143.951 41.0649C142.417 40.0891 141.225 38.7205 140.376 36.9464C139.539 35.1722 139.108 33.1193 139.108 30.7876C139.108 28.6206 139.552 26.6944 140.427 24.9836C141.301 23.2855 142.48 21.9422 144.002 20.9411C145.511 19.94 147.235 19.4458 149.175 19.4458C150.722 19.4458 152.129 19.7372 153.384 20.3455C154.652 20.9538 155.717 21.8028 156.592 22.9053C157.454 23.9952 158.113 25.2877 158.544 26.7577C158.963 28.2404 159.115 29.8752 158.975 31.6746H140.693V29.6471H155.945C155.831 27.1506 155.172 25.1991 153.967 23.8051C152.763 22.4111 151.153 21.7141 149.124 21.7141C147.717 21.7141 146.474 22.0943 145.409 22.842C144.344 23.577 143.507 24.6288 142.899 25.9847C142.29 27.328 141.986 28.9374 141.986 30.7876C141.986 32.7011 142.278 34.3739 142.848 35.7932C143.431 37.2125 144.268 38.3023 145.371 39.0627C146.462 39.823 147.767 40.2032 149.289 40.2032C151.127 40.2032 152.636 39.6963 153.841 38.6952C155.045 37.7067 155.831 36.3381 156.186 34.5893H159.064C158.557 37.0351 157.441 38.9739 155.717 40.3933C153.98 41.8126 151.85 42.522 149.34 42.522"
                    fill={fill}/>
                <path
                    d="M169.486 30.965C169.486 33.8036 170.132 36.0086 171.438 37.58C172.744 39.164 174.456 39.9497 176.599 39.9497C178.031 39.9497 179.286 39.5822 180.351 38.8472C181.416 38.1122 182.253 37.0731 182.849 35.7045C183.445 34.3359 183.736 32.7264 183.736 30.8763C183.736 29.0134 183.432 27.4294 182.798 26.1115C182.177 24.7809 181.34 23.7671 180.275 23.0701C179.198 22.3604 177.981 22.0183 176.599 22.0183C174.456 22.0183 172.744 22.804 171.438 24.3753C170.132 25.9467 169.486 28.1517 169.486 30.965V30.965ZM166.532 30.965C166.532 28.684 166.938 26.6817 167.724 24.9456C168.51 23.2222 169.625 21.8662 171.071 20.8904C172.503 19.9273 174.215 19.4458 176.18 19.4458C177.816 19.4458 179.286 19.8893 180.618 20.7637C181.949 21.6508 182.976 22.9053 183.699 24.5021V11.8043H186.602V42.1037H183.901V37.4153C183.128 39.012 182.063 40.2665 180.732 41.1663C179.388 42.066 177.866 42.522 176.18 42.522C174.215 42.522 172.503 42.0408 171.071 41.0649C169.625 40.0891 168.51 38.7332 167.724 36.9971C166.938 35.2483 166.532 33.2334 166.532 30.965Z"
                    fill={fill}/>
                <path
                    d="M228.276 30.965C228.276 28.1517 227.617 25.9467 226.311 24.3753C224.993 22.804 223.268 22.0183 221.138 22.0183C219.782 22.0183 218.552 22.3604 217.474 23.0701C216.396 23.7671 215.547 24.7809 214.938 26.1115C214.33 27.4294 214.026 29.0134 214.026 30.8763C214.026 32.7264 214.33 34.3359 214.926 35.7045C215.522 37.0731 216.359 38.1122 217.436 38.8472C218.514 39.5822 219.744 39.9497 221.138 39.9497C223.268 39.9497 224.993 39.164 226.311 37.58C227.617 36.0086 228.276 33.8036 228.276 30.965V30.965ZM231.23 30.965C231.23 33.2334 230.838 35.2483 230.051 36.9971C229.253 38.7332 228.137 40.0891 226.704 41.0649C225.259 42.0408 223.56 42.522 221.595 42.522C219.934 42.522 218.413 42.066 217.056 41.1663C215.699 40.2665 214.634 39.012 213.861 37.4153V42.1037H211.16V11.8043H214.064V24.5401C214.787 22.918 215.813 21.6508 217.119 20.7637C218.438 19.8893 219.934 19.4458 221.595 19.4458C223.56 19.4458 225.259 19.9273 226.679 20.8904C228.112 21.8662 229.227 23.2222 230.026 24.9456C230.825 26.6817 231.23 28.684 231.23 30.965Z"
                    fill={fill}/>
                <path
                    d="M256.904 19.864L245.684 50.2397H242.578L245.773 41.7999L237.088 19.864H240.207L247.269 38.2643L253.963 19.864H256.904Z"
                    fill={fill}/>
                <path
                    d="M302.877 40.9001H307.758V50.5183H300.138C297.222 50.5183 294.699 49.961 292.544 48.82C290.401 47.6799 288.753 46.0448 287.625 43.8905C286.483 41.7491 285.913 39.202 285.913 36.2366V19.2049H278.661V16.9112L294.509 0.107623H296.512V10.5497H307.543V19.2049H296.728V34.83C296.728 36.7562 297.26 38.2516 298.325 39.3034C299.378 40.3678 300.899 40.9001 302.877 40.9001"
                    fill={fill}/>
                <path
                    d="M324.671 37.1997C326.713 39.2653 329.312 40.3045 332.443 40.3045C335.588 40.3045 338.187 39.2653 340.253 37.1997C342.333 35.1215 343.372 32.5109 343.372 29.3555C343.372 26.2001 342.333 23.5769 340.253 21.5113C338.187 19.433 335.6 18.3939 332.481 18.3939C330.415 18.3939 328.551 18.8754 326.89 19.8385C325.242 20.8016 323.936 22.1069 323.01 23.767C322.06 25.4144 321.603 27.2772 321.603 29.3555C321.603 32.5109 322.617 35.1215 324.671 37.1997V37.1997ZM345.883 9.37114H353.959V49.3397H345.439L344.551 45.7791C340.9 48.9472 336.577 50.5183 331.594 50.5183C325.572 50.5183 320.576 48.5159 316.595 44.4861C312.627 40.4692 310.636 35.4256 310.636 29.3555C310.636 23.3361 312.627 18.3179 316.595 14.326C320.576 10.3342 325.572 8.33201 331.594 8.33201C336.678 8.33201 341.052 9.95408 344.703 13.2109L345.883 9.37114"
                    fill={fill}/>
                <path
                    d="M377.364 50.5183C374.055 50.5183 371.126 49.9358 368.59 48.7442C366.042 47.5655 364.064 45.8806 362.657 43.7134C361.249 41.5464 360.552 39.0499 360.552 36.2366H371.062C371.113 37.4152 371.43 38.4163 371.988 39.2273C372.559 40.051 373.332 40.634 374.321 41.0141C375.31 41.3816 376.451 41.5591 377.731 41.5591C378.771 41.5591 379.709 41.4197 380.546 41.1155C381.383 40.8241 382.08 40.3678 382.625 39.7469C383.158 39.1386 383.437 38.4036 383.437 37.5672C383.437 36.338 382.79 35.4256 381.51 34.83C380.229 34.2344 378.302 33.7148 375.728 33.2713C374.105 33.0305 372.761 32.7771 371.696 32.5363C370.631 32.2829 369.376 31.852 367.918 31.2437C366.46 30.6228 365.306 29.9258 364.444 29.1274C363.569 28.3417 362.821 27.2519 362.175 25.8706C361.541 24.4893 361.211 22.8926 361.211 21.0678C361.211 17.3167 362.695 14.25 365.661 11.8803C368.628 9.51054 372.508 8.33201 377.326 8.33201C382.143 8.33201 386.01 9.54855 388.952 11.9943C391.893 14.4401 393.351 17.6589 393.351 21.6507H383.069C383.069 18.4953 381.066 16.9112 377.059 16.9112C375.386 16.9112 374.08 17.266 373.142 17.9504C372.203 18.6473 371.734 19.5851 371.734 20.7636C371.734 21.2579 371.874 21.7141 372.14 22.1322C372.406 22.5504 372.736 22.8926 373.104 23.1334C373.471 23.3868 374.055 23.6149 374.841 23.843C375.627 24.0585 376.299 24.2232 376.844 24.3246C377.389 24.4133 378.2 24.5654 379.291 24.7681C380.229 24.9582 380.888 25.0976 381.281 25.1736C381.687 25.2497 382.346 25.3764 383.285 25.5792C384.223 25.7692 384.933 25.9593 385.402 26.1367C385.871 26.3015 386.505 26.5423 387.316 26.8337C388.141 27.1252 388.749 27.442 389.167 27.7588C389.599 28.0756 390.105 28.4684 390.727 28.912C391.348 29.3555 391.83 29.8498 392.172 30.3947C392.514 30.9269 392.87 31.5225 393.212 32.1688C393.554 32.8024 393.795 33.5501 393.909 34.3865C394.036 35.2229 394.099 36.1099 394.099 37.0477C394.099 41.1409 392.565 44.4103 389.51 46.8557C386.441 49.3021 382.397 50.5183 377.364 50.5183"
                    fill={fill}/>
                <path
                    d="M419.305 50.5183C415.362 50.5183 411.761 49.5931 408.528 47.7433C405.295 45.8929 402.759 43.3461 400.908 40.0891C399.057 36.8322 398.131 33.2206 398.131 29.2795C398.131 23.3108 400.147 18.3179 404.204 14.326C408.249 10.3342 413.308 8.33201 419.381 8.33201C424.566 8.33201 429.004 9.82735 432.706 12.8054C436.408 15.796 438.754 19.7498 439.743 24.6921H429.004C428.116 22.7659 426.823 21.2452 425.124 20.13C423.412 19.0275 421.485 18.4699 419.305 18.4699C416.388 18.4699 413.967 19.5091 412.014 21.5747C410.062 23.6529 409.086 26.2381 409.086 29.3555C409.086 32.4603 410.075 35.0834 412.053 37.1997C414.018 39.316 416.439 40.3805 419.305 40.3805C421.485 40.3805 423.4 39.7976 425.086 38.6444C426.76 37.4785 428.065 35.8184 429.004 33.6515H439.895C438.906 38.7331 436.535 42.8134 432.782 45.8929C429.029 48.9853 424.541 50.5183 419.305 50.5183"
                    fill={fill}/>
                <path
                    d="M465.1 40.3805C468.206 40.3805 470.754 39.3541 472.719 37.3138C474.697 35.2609 475.686 32.6377 475.686 29.4316C475.686 26.2128 474.697 23.5896 472.719 21.5367C470.754 19.4964 468.193 18.4699 465.061 18.4699C461.917 18.4699 459.369 19.4964 457.391 21.5367C455.413 23.5896 454.437 26.2128 454.437 29.4316C454.437 31.0029 454.69 32.4856 455.21 33.8669C455.73 35.2482 456.453 36.4141 457.391 37.3518C458.329 38.2896 459.457 39.0246 460.763 39.5695C462.069 40.1144 463.515 40.3805 465.1 40.3805V40.3805ZM465.1 50.5183C460.992 50.5183 457.289 49.606 453.993 47.7815C450.684 45.9562 448.097 43.4342 446.246 40.1904C444.395 36.959 443.47 33.3727 443.47 29.4316C443.47 23.3615 445.524 18.3179 449.619 14.326C453.714 10.3342 458.874 8.33201 465.1 8.33201C471.261 8.33201 476.396 10.3342 480.504 14.326C484.599 18.3179 486.64 23.3615 486.64 29.4316C486.64 35.451 484.586 40.4692 480.466 44.4861C476.345 48.5159 471.223 50.5183 465.1 50.5183"
                    fill={fill}/>
                <path
                    d="M552.709 41.1155V25.4271C552.709 20.789 551.39 16.9873 548.753 14.0346C546.103 11.0693 542.718 9.58657 538.572 9.58657C537.735 9.58657 536.898 9.66261 536.087 9.81468C535.275 9.95408 534.489 10.1568 533.716 10.3976C532.955 10.6511 532.233 10.9552 531.535 11.3227C530.851 11.7029 530.179 12.1211 529.532 12.5899C528.898 13.0588 528.302 13.5784 527.757 14.136C527.212 14.7062 526.705 15.3398 526.21 16.0242C525.069 14.0092 523.497 12.4252 521.469 11.2847C519.44 10.1568 517.17 9.58657 514.66 9.58657C512.34 9.58657 510.134 10.1188 508.029 11.1833C505.937 12.2478 504.15 13.6798 502.666 15.5046L500.663 10.5497H493.119V50.5183H503.921V28.912C503.921 25.7946 504.619 23.3868 506.001 21.6507C507.383 19.9273 509.31 19.0655 511.77 19.0655C515.675 19.0655 517.614 21.7774 517.614 27.2012V50.5183H528.201V28.912C528.201 26.8337 528.505 25.0596 529.088 23.5769C529.684 22.0942 530.559 20.9791 531.726 20.2061C532.879 19.4457 534.248 19.0655 535.833 19.0655C539.878 19.0655 541.894 21.7774 541.894 27.2012V50.5183H543.745L552.709 41.1155Z"
                    fill={fill}/>
            </svg>
        </Box>
    );
}
