import React from "react";
import {Box} from "@mui/material";

interface IPropsSvg {
    fill: any;
    width?: any;
    height?: any;
}

export default function THCSIconClose(props: IPropsSvg) {
    const {fill, width, height} = props;

    return (
        <svg width={width ? width : "16"} height={height ? height : "16"} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.2626 15.9626L0.0375977 14.7376L6.7751 8.0001L0.0375977 1.2626L1.2626 0.0375977L8.0001 6.7751L14.7376 0.0375977L15.9626 1.2626L9.2251 8.0001L15.9626 14.7376L14.7376 15.9626L8.0001 9.2251L1.2626 15.9626Z" fill={fill}/>
        </svg>
    );
}
