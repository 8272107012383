import React from "react";
import {Box, Toolbar, useTheme} from "@mui/material";
import THCSPoweredBy from "../../Icons/THCSPoweredBy";
import THCSLogo from "../../Icons/THCSLogo";
import Copyright from "../../Text/Copyright";
import TypographyText from "../../Text";
import THCSIconElipse from "../../Icons/THCSIconElipse";
import useHealth from "../../../services/useHealth";


const BottomBar: React.FC = () => {
    const theme = useTheme();

    const {getHealth} = useHealth();
    return (
        <Toolbar sx={{
            backgroundColor: `primary.main`,
            height: 'auto',
            minHeight: '30px !important',
            paddingLeft: '0 !important',
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: window.innerWidth > 520 ? 'space-between' : 'center',
            position: 'fixed',
            bottom: 0,
            width: '100%',
        }}>
            <Box sx={{
                width: '260px',
                alignSelf: 'baseline',
                marginTop: '5px',
                display: 'flex',
                marginLeft: '10px',
                justifyContent: window.innerWidth > 520 ? 'null' : 'center',
            }}
            >
                <THCSLogo fill={theme.palette.common.white} height={'20px'} width={'20px'} sx={{marginRight: '10px'}}/>
                <Copyright/>
            </Box>
            <Box sx={{
                display: 'flex',
            }}>
                {
                    !(getHealth.isFetched) ? (
                        <>
                            <TypographyText sx={{color: theme.palette.common.white}}>Status:</TypographyText>
                            <THCSIconElipse fill={theme.palette.warning.main} width={'10px'} height={'10px'}
                                            sx={{margin: '0px 5px 0px 10px'}}/>
                            <TypographyText sx={{color: theme.palette.warning.main}}>Verificando
                                Integração</TypographyText>
                        </>
                    ) : getHealth.isSuccess ? (
                        <>
                            <TypographyText sx={{color: theme.palette.common.white}}>Status:</TypographyText>
                            <THCSIconElipse fill={theme.palette.success.main} width={'10px'} height={'10px'}
                                            sx={{margin: '0px 5px 0px 10px'}}/>
                            <TypographyText sx={{color: theme.palette.success.main}}>Integração Ok</TypographyText>
                        </>
                    ) : (
                        <>
                            <TypographyText sx={{color: theme.palette.common.white}}>Status:</TypographyText>
                            <THCSIconElipse fill={theme.palette.error.main} width={'10px'} height={'10px'}
                                            sx={{margin: '0px 5px 0px 10px'}}/>
                            <TypographyText sx={{color: theme.palette.error.main}}>Falha Na Integração</TypographyText>
                        </>
                    )
                }
            </Box>
            <Box sx={{
                minWidth: '250px',
            }}
            >
                <THCSPoweredBy fill={theme.palette.common.white} height={'20px'} width={'250px'}/>
            </Box>
        </Toolbar>
    );
};

export default BottomBar;
