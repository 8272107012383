import React from "react";
import {Box} from "@mui/material";

interface IPropsSvg {
    fill: any;
    inColor: any;
    width?: any;
    height?: any;
    sx?: any;
}

export default function THCSIconHouse(props: IPropsSvg) {
    const {fill, inColor, width, height, sx} = props;
    return (
        <Box sx={{fill: fill, width: width, height: height, ...sx}}>
            <svg
                width={width ? width : "29"}
                height={height ? height : "29"}
                viewBox={`0 0 29 29`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M11.6667 24.1667H12.5833H15.5833H16.5H22.5417V14.5H26.1667L14.0833 3.625L2 14.5H5.625V24.1667H11.6667Z" fill={fill}/>
                <path d="M15.3333 20H12.6667V18.6667H15.3333V20ZM18 16.6667H10V15.3333H18V16.6667ZM20 13.3333H8V12H20V13.3333Z" fill={inColor}/>
            </svg>
        </Box>
    );
}
