import React from "react";
import {Box} from "@mui/material";

interface IPropsSvg {
    fill: any;
    width?: any;
    height?: any;
    sx?: any;
}

export default function THCSLogo(props: IPropsSvg) {
    const {fill, width, height, sx} = props;

    return (
        <Box sx={{...sx, fill: fill, width: width, height: height}}>
            <svg
                width={width ? width : "500"}
                height={height ? height : "500"}
                viewBox={`0 0 500 500`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M312.541 187.5V0.00244141H187.5V187.503H-0.00268555V312.503H31.2489L249.999 250.003L468.749 312.503H499.998V187.503H312.541"
                    fill={fill}/>
                <path d="M250.032 343.758L187.492 361.6V500H312.51V361.6L250.019 343.758" fill={fill}/>
            </svg>
        </Box>
    );
}
