import React, {useMemo} from 'react';
import {Navigate, useLocation} from "react-router-dom";
import useStateWithLocalStorage from "../hooks/useStateWithLocalStorage";
// @ts-ignore
import CryptoJS from 'crypto-js'

export interface IPayload {
    token: string;
    integration_url: string;
    integration_token: string;
    external_sector_id: string;
    company_id: string;
    external_company_id: string;
    external_user_id: string;
}

export const InitialValuePayload = {
    company_id: "",
    external_company_id: "",
    external_sector_id: "",
    external_user_id: "",
    integration_url: "",
    integration_token: "",
    token: "",
}

function TokenPage() {
    const location = useLocation();

    const [payload, setPayload] = useStateWithLocalStorage<IPayload>('THCS-MAP-PAYLOAD', InitialValuePayload);

    function decryptPayload(hash: string, key: string) {
        try {
            const bytes = CryptoJS.AES.decrypt(CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(hash)), key)
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
        } catch (e) {
            return null
        }
    }

    const hash = location.pathname.split('/hash/')[1]
    const key = process.env.REACT_APP_HCS_CHIPER_KEY || ''

    useMemo(() => setPayload(decryptPayload(hash, key)), [])

    return <Navigate to={"/map"} replace={true}/>
}

export default TokenPage;
