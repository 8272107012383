import {Marker, InfoBox, InfoWindowF, Circle} from "@react-google-maps/api";
import React, {useMemo, useState} from "react";
import useEvents from "../../services/useEvents";
import handleStatus from "../../utils/status";
import {Box, Typography, useTheme} from "@mui/material";
import getDuration from "../../utils/date";
import {GlobalContext} from "../../contexts/GlobalContext";
import _ from "lodash";
import handleText from "../../utils/text";
import MarkerWindows from "../Map/Windows";
import TypographyText from "../Text";
import Timer from "../Text/Timer";
import THCSIconPatient from "../Icons/THCSIconPatient";
import {keyframes} from "@emotion/react";

const MarkerView: React.FC<any> = ({}: any) => {
    const theme = useTheme();
    const {getEvents} = useEvents();

    const [markerMap, setMarkerMap] = useState({});


    const {
        mapRef,
        setCenter,
        activeMarker,
        setActiveMarker,
        setMarkerCenter,
        markerClickHandler,
        areaChecks,
        filterChecks,
        configChecks,
    } = React.useContext(GlobalContext);

    const markerLoadHandler = (marker: any, attendance: any) => {
        return setMarkerMap(prevState => {
            return {...prevState, [attendance.attendance_id]: marker};
        });
    };

    const fitBounds = (map: any, attendances: any) => {
        if (map) {
            const bounds = new window.google.maps.LatLngBounds();
            attendances.map((attendance: any) => {
                if (attendance.position.lat && attendance.position.lng) {
                    const LatLng = new google.maps.LatLng(attendance.position.lat, attendance.position.lng)
                    bounds.extend(LatLng);
                    return attendance._id;
                }
            });

            map.fitBounds(bounds, {top: 150, left: 0, right: 0, bottom: 30});
            setCenter(bounds.getCenter().toJSON());
            setMarkerCenter(bounds.getCenter().toJSON());
        }
    };

    useMemo(() => {
        if (getEvents.isSuccess) {
            fitBounds(mapRef, getEvents.data)
        }
    }, [getEvents.isFetched])

    const movingRightTop = keyframes({
        from: {
            border: '11px solid #666'
        },
        to: {
            border: 'none'
        }
    })

    const breatheAnimation = keyframes({
        // '0%': {
        //     boxShadow: '0 0 0 0 rgba(255, 199, 0, 0.7), 0 0 0 5px rgba(255, 199, 0, 0.6)',
        //     background: 'rgba(54, 54, 27, 0)'
        // },
        // '50%': {
        //     boxShadow: '0 0 0 5px rgba(255, 199, 0, 0.7), 0 0 0 15px rgba(255, 199, 0, 0.6)',
        //     background: 'rgba(54, 54, 27, 0.5)'
        // },
        // '100%': {
        //     boxShadow: '0 0 0 0 rgba(255, 199, 0, 0.7), 0 0 0 5px rgba(54, 54, 27, 0)',
        //     background: 'rgba(54, 54, 27, 0)'
        // },
    })

    return (
        <>
            {
                getEvents.isSuccess &&
                getEvents.data.map((attendance: any) => {
                    const status = handleStatus(attendance.status, theme)
                    const LatLng = new google.maps.LatLng(attendance.position.lat, attendance.position.lng)

                    // TODO REMOVE DUP CODE (PUT IN CONTEXT)
                    const activeAreas: any = []
                    const activeFilters: any = []

                    _.map(areaChecks, ((area, key) => {
                        if (area.value) {
                            activeAreas.push(area.title)
                        }
                    }))

                    _.map(filterChecks, ((filter, key) => {
                        if (filter.value) {
                            activeFilters.push(key)
                        }
                    }))

                    // if (attendance.status === 'dischargedLate') {
                    //     attendance.status = 'discharged'
                    // }

                    const statusName = attendance.status === 'dischargedLate' ? 'discharged' : attendance.status
                    const visible = _.includes(activeAreas, attendance.area) && _.includes(activeFilters, statusName)

                    function hexToRgb(hex: string) {
                        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
                        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
                            return r + r + g + g + b + b;
                        });

                        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                        return result ? {
                            r: parseInt(result[1], 16),
                            g: parseInt(result[2], 16),
                            b: parseInt(result[3], 16),
                        } : {
                            r: 0,
                            g: 0,
                            b: 0,
                        };
                    }

                    const rgb = hexToRgb(status.hex)

                    const rippleAnimation = keyframes({
                        '0%': {
                            boxShadow: `0 0 0 0 rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.7),` +
                                `0 0 0 5px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.6),` +
                                `0 0 0 15px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5),` +
                                `0 0 0 25px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.4)`,
                        },
                        '100%': {
                            // background: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.8)`, // icon pulse
                            boxShadow: `0 0 0 5px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.6),` +
                                `0 0 0 15px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5),` +
                                `0 0 0 25px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.4),` +
                                `0 0 0 40px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0)`,
                        }
                    })
                    const rippleOutAnimation = keyframes({
                        'from': {
                            opacity: 1,
                            background: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5)`,
                            transform: 'scale3d(0.4, 0.4, 1)',
                        },
                        '10%': {
                            opacity: 1,
                            background: 'none',
                            transform: 'scale3d(0.4, 0.4, 1)',
                        },
                        'to%': {
                            opacity: 0,
                            transform: 'scale3d(1.0, 1.0, 1)',
                        }
                    })

                    const breatheAnimation = keyframes({
                        '0%': {
                            background: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0)`,
                            boxShadow: `0 0 0 0px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.7),` +
                                `0 0 0 5px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.6)`,
                        },
                        '50%': {
                            background: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5)`,
                            boxShadow: `0 0 0 5px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.7),` +
                                `0 0 0 15px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.6)`,
                        },
                        '100%': {
                            background: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0)`,
                            boxShadow: `0 0 0 0px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.7),` +
                                `0 0 0 5px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.6)`,
                        }
                    })

                    const pulseAnimation = keyframes({
                        '0%': {
                            boxShadow: `0 0 12px 20px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5)`
                        },
                        '50%': {
                            boxShadow: `0 0 15px 25px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.9)`
                        },
                        '100%': {
                            boxShadow: `0 0 12px 20px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5)`
                        }
                    })


                    if (attendance.status !== 'no_geo' && (attendance.position.lat && attendance.position.lng)) {
                        return (
                            <Marker
                                key={'Marker' + attendance.attendance_id}
                                position={LatLng}
                                onLoad={marker => markerLoadHandler(marker, attendance)}
                                onClick={event => markerClickHandler(event, attendance, LatLng)}
                                visible={visible}
                                title={status.color}
                                icon={{
                                    url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(status.icon),
                                }}
                                zIndex={1000}
                            >
                                {activeMarker === attendance.attendance_id ? (
                                    <InfoWindowF
                                        // onCloseClick={() => markerClickHandler(null, attendance, LatLng)}
                                        options={{
                                            zIndex: 25,
                                        }}
                                        position={LatLng}
                                    >
                                        <MarkerWindows
                                            status={attendance.status}
                                            attendance={attendance}
                                            LatLng={LatLng}
                                        />
                                    </InfoWindowF>
                                ) : (
                                    <InfoBox
                                        key={'Label' + attendance._id}
                                        options={{
                                            closeBoxURL: '',
                                            enableEventPropagation: true,
                                            disableAutoPan: true,
                                            alignBottom: false,
                                            zIndex: 10,
                                            boxStyle: {
                                                opacity: 1,
                                                width: "auto",
                                                borderRadius: '15px',
                                            },
                                            pixelOffset: new google.maps.Size(-50, -70),
                                        }}
                                        // @ts-ignore
                                        visible={visible}
                                    >
                                        <Box sx={{
                                            backgroundColor: theme.palette.primary.main,
                                            opacity: 0.95,
                                            borderRadius: '10px',
                                            padding: '5px 10px 5px 10px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                        >
                                            <TypographyText color={theme.palette.common.white}>
                                                {handleText(attendance.label)}
                                            </TypographyText>
                                        </Box>
                                    </InfoBox>
                                )}
                                <InfoBox
                                    key={'Timer' + attendance._id + attendance.time}
                                    options={{
                                        closeBoxURL: '',
                                        enableEventPropagation: true,
                                        disableAutoPan: true,
                                        alignBottom: false,
                                        zIndex: 10,
                                        boxStyle: {
                                            opacity: 1,
                                            width: "auto",
                                            borderRadius: '15px',
                                        },
                                        pixelOffset: new google.maps.Size(-30, 5),
                                    }}
                                    // @ts-ignore
                                    visible={visible && attendance.status !== 'done'}
                                >
                                    <Box style={{
                                        backgroundColor: theme.palette.primary.main,
                                        opacity: 0.95,
                                        borderRadius: '10px',
                                        padding: '5px 10px 5px 10px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                    >
                                        <Timer
                                            sx={{
                                                paddingLeft: '5px',
                                                color: theme.palette.common.white
                                            }}>
                                            {attendance.time}
                                        </Timer>
                                    </Box>
                                </InfoBox>
                                <InfoBox
                                    key={'Animation' + attendance._id}
                                    options={{
                                        closeBoxURL: '',
                                        enableEventPropagation: true,
                                        disableAutoPan: true,
                                        alignBottom: false,
                                        zIndex: 1,
                                        boxStyle: {
                                            opacity: 1,
                                            width: "100px",
                                            height: '100px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        },
                                        pixelOffset: new google.maps.Size(-50, -70),
                                    }}
                                    // @ts-ignore
                                    visible={visible && attendance.status !== 'done'}
                                >
                                    <Box
                                        onClick={(event) => markerClickHandler(event, attendance, LatLng)}
                                        sx={
                                            !(configChecks.animations.value) ? {} :
                                                attendance.status === 'no_prescription' ?
                                                    {
                                                        backgroundColor: 'transparent',
                                                        opacity: 0.7,
                                                        width: '40px',
                                                        height: '40px',
                                                        borderRadius: '50%',
                                                        zIndex: 1,

                                                        "&:before": {
                                                            opacity: '0',
                                                            content: `""`,
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            position: 'absolute',
                                                            top: '0',
                                                            left: '0',
                                                            right: '0',
                                                            bottom: '0',
                                                            height: '100%',
                                                            width: '100%',
                                                            border: `8px solid rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5)`,
                                                            borderRadius: '100%',
                                                            animationName: `${rippleOutAnimation}`,
                                                            animationDuration: '3s',
                                                            animationDelay: '0.5s',
                                                            animationIterationCount: 'infinite',
                                                            animationTimingFunction: 'cubic-bezier(.65, 0, .34, 1)',
                                                        },

                                                        "&:after": {
                                                            opacity: '0',
                                                            content: `""`,
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            position: 'absolute',
                                                            top: '0',
                                                            left: '0',
                                                            right: '0',
                                                            bottom: '0',
                                                            height: '100%',
                                                            width: '100%',
                                                            border: `8px solid rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5)`,
                                                            borderRadius: '100%',
                                                            animationName: `${rippleOutAnimation}`,
                                                            animationDuration: '3s',
                                                            animationDelay: '0s',
                                                            animationIterationCount: 'infinite',
                                                            animationTimingFunction: 'cubic-bezier(.65, 0, .34, 1)',
                                                        },

                                                    } :
                                                    {
                                                        animation: (attendance.status === 'dischargedLate' || attendance.status === 'late') ?
                                                            `${rippleAnimation} 0.7s linear infinite` :
                                                            attendance.status === 'removed' ? `${breatheAnimation} 3s linear infinite` :
                                                                attendance.status === 'tele' ? `${pulseAnimation} 2s ease-in infinite` :
                                                                    '',
                                                        transform: (attendance.status === 'dischargedLate' || attendance.status === 'late') ?
                                                            `scale(0.8)` :
                                                            attendance.status === 'removed' ? `scale(0.9)` :
                                                                attendance.status === 'tele' ? `scale(0.7)` :
                                                                    'scale(1)',
                                                        backgroundColor: 'transparent',
                                                        opacity: 0.7,
                                                        width: '40px',
                                                        height: '40px',
                                                        borderRadius: '50%',
                                                        zIndex: 1,
                                                    }}
                                    >
                                    </Box>
                                </InfoBox>
                            </Marker>
                        )
                    }
                })}
        </>
    )
};
export default MarkerView;
