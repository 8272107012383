import React, {useState} from "react";
import {
    GoogleMap,
    useLoadScript,
} from '@react-google-maps/api'

import {
    Typography
} from "@mui/material";
import CardList from "../Card/AttendanceList";
import {GlobalContext} from "../../contexts/GlobalContext";
import MarkerView from "../Markes";
import Loading from "../Loading";
import TypographyText from "../Text";
import ZoomButton from "../Button/Zoom";


const options = {
    fullscreenControl: false,
    streetViewControl: false,
    zoomControl: false,
    mapTypeControl: false,
    clickableIcons: false,
}

interface IMapProps {
}

const MapView: React.FC<IMapProps> = () => {
    const {
        setMapRef,
        zoom,
        center,
        activeMarker,
        setActiveMarker,
        markerClickHandler,
    } = React.useContext(GlobalContext);

    const [state, setState] = useState<number>(0)

    const {isLoaded} = useLoadScript({
        googleMapsApiKey: "AIzaSyA5ynBs1BxZYrCebESiQloFSZIiALVBGzg" // ,
    })

    const loadHandler = (map: any) => {
        // Store a reference to the google map instance in state
        setTimeout(() => setMapRef(map));
    };

    const infoCloseHandler = () => {
        // TODO VERIFICAR OQUE FAZER
        // setState((prev) => {
        //     if (state > 0 && activeMarker) {
        //         setActiveMarker(null)
        //         return 0
        //     }
        //     return (prev + 1)
        // })
    }

    return (
        <>
            {window.google && isLoaded ? (
                // <div id="map_canvas" className={styles.map_canvas}>
                <GoogleMap
                    id="thcs-map"
                    mapContainerClassName="map-container"
                    options={options}
                    onLoad={loadHandler}
                    onClick={infoCloseHandler}
                    onDrag={infoCloseHandler}
                    onZoomChanged={infoCloseHandler}
                    center={center}
                    zoom={zoom}
                    mapContainerStyle={{
                        height: "100vh",
                        width: "100%"
                    }}
                >
                    <CardList onClick={markerClickHandler}/>
                    <MarkerView/>
                    <ZoomButton/>
                    <Loading/>
                </GoogleMap>
                // </div>
            ) : (
                <>
                    <TypographyText>Não foi possível carregar o mapa, por favor tente novamente!</TypographyText>
                </>
            )}
        </>
    );
};

export default MapView;
