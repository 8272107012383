import React from "react";
import {Box} from "@mui/material";

interface IPropsSvg {
    fill: any;
    stroke?: any;
    width?: any;
    height?: any;
    sx?: any;
}

export default function THCSIconHours(props: IPropsSvg) {
    const {fill, width, height, sx} = props;

    return (
        <Box sx={{fill: fill, width: width, height: height, ...sx}}>
            <svg
                width={width ? width : "17"}
                height={height ? height : "17"}
                viewBox={`0 0 17 17`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M8.88282 1.9285C7.55077 1.9285 6.24863 2.31391 5.14108 3.03599C4.03352 3.75806 3.17028 4.78438 2.66053 5.98515C2.15078 7.18592 2.0174 8.50722 2.27727 9.78195C2.53714 11.0567 3.17858 12.2276 4.12048 13.1466C5.06238 14.0657 6.26244 14.6915 7.56889 14.9451C8.87535 15.1987 10.2295 15.0685 11.4602 14.5711C12.6908 14.0738 13.7427 13.2315 14.4827 12.1508C15.2228 11.0702 15.6178 9.79964 15.6178 8.49993C15.6178 7.63696 15.4436 6.78244 15.1051 5.98515C14.7666 5.18787 14.2705 4.46344 13.6451 3.85323C13.0197 3.24302 12.2773 2.75897 11.4602 2.42872C10.643 2.09848 9.76726 1.9285 8.88282 1.9285ZM4.49034 2.08574C5.79051 1.23808 7.31911 0.785645 8.88282 0.785645C9.92108 0.785645 10.9492 0.98518 11.9084 1.37286C12.8676 1.76054 13.7392 2.32877 14.4734 3.04511C15.2075 3.76144 15.7899 4.61186 16.1872 5.5478C16.5846 6.48374 16.7891 7.48688 16.7891 8.49993C16.7891 10.0257 16.3254 11.5171 15.4566 12.7858C14.5879 14.0544 13.3531 15.0431 11.9084 15.627C10.4637 16.2109 8.87405 16.3636 7.34038 16.066C5.80672 15.7683 4.39796 15.0336 3.29225 13.9548C2.18654 12.8759 1.43355 11.5013 1.12848 10.0049C0.823418 8.50849 0.979988 6.9574 1.57839 5.5478C2.1768 4.1382 3.19016 2.93339 4.49034 2.08574Z" fill={fill}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M8.88277 3.64258C9.20621 3.64258 9.46842 3.89842 9.46842 4.21401V8.14656L12.0729 9.41719C12.3622 9.55833 12.4795 9.90157 12.3348 10.1838C12.1902 10.4661 11.8384 10.5805 11.5491 10.4394L8.62086 9.01082C8.42245 8.91403 8.29712 8.71616 8.29712 8.49972V4.21401C8.29712 3.89842 8.55932 3.64258 8.88277 3.64258Z" fill={fill}/>
            </svg>
        </Box>
    );
}
