import {useEffect, useState} from "react";
import _ from "lodash";

export default function useStateWithLocalStorage<T>(
    key: string,
    initialValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
    let localValue = JSON.parse(localStorage.getItem(key) ?? '{}')
    if (!localValue) localValue = {}
    localValue = Object.keys(localValue).length > 0 ? localValue : initialValue

    const [value, setValue] = useState<T>(localValue);

    useEffect(() => {
        try {
            const item = localStorage.getItem(key);

            // _.map(item, (obj) = {})

            if (item) setValue(JSON.parse(item) as T)
        } catch (error) {
            console.warn(`Error reading localStorage key “${key}”:`, error);
        }
    }, [setValue, key]);

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [value, key]);

    return [value, setValue];
}