import React from "react";
import {Typography, useTheme} from "@mui/material";
import TypographyText from "../index";


const Copyright: React.FC = () => {
    const theme = useTheme();
    return (
        <TypographyText variant="body2" align="center" sx={{color: theme.palette.common.white}}>
            {`Copyright © T+HCS${new Date().getFullYear()}`}
        </TypographyText>
    );
};

export default Copyright;
