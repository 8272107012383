import React, {memo, useEffect, useLayoutEffect, useState} from "react";
import {keyframes, Box, useTheme} from "@mui/material";

const BackgroundAnimated = (): any => {
    const layout = [];
    const theme = useTheme();

    const arrayColor: string[] = [
        theme.palette.primary.main,
        theme.palette.secondary.main,
        theme.palette.common.white,
        theme.palette.tertiary.main,
    ];


    const arrayLayout = [
        ["", "", "", ""],
        ["", "", "", ""],
        ["", "", "", ""],
        ["", "", ""],
        ["", "", ""],
        ["", ""],
        ["", ""],
        ["", ""],
        [""],
        [""],
        [""],
        [""],
    ];

// setInterval(() => {
//   console.log("entroo");
// }, 1000);

    function colorRandom(arrayLayout: string[][]) {
        const array = arrayLayout;

        const changeColor = keyframes`
          0% {
            opacity: 0;
            background: ${arrayColor[Math.floor(Math.random() * 4)]};


          }
          25% {
            opacity: 1;

          }
          50% {
            background: ${arrayColor[Math.floor(Math.random() * 4)]};
            opacity: 1;

          }
          100% {
            opacity: 0;
              /* background: ${arrayColor[Math.floor(Math.random() * 4)]} */
          }
        `;

        for (let i = 0; i < array.length; i++) {
            for (let u = 0; u < array[i].length; u++) {
                let box1 = document.getElementById(`${i}-${u}-v1`);
                let box2 = document.getElementById(`${i}-${u}-v2`);
                if (box1) {
                    box1.style.backgroundColor = arrayColor[Math.floor(Math.random() * 4)];
                }
                if (box2) {
                    box2.style.backgroundColor = arrayColor[Math.floor(Math.random() * 4)];
                }
            }
        }
    }

    const interval = setInterval(() => {
        colorRandom(arrayLayout);
        // console.log("roletou");
        // clearInterval(interval);
    }, 7000);

    for (let i = 0; i < arrayLayout.length; i++) {
        const row = [];
        for (let u = 0; u < arrayLayout[i].length; u++) {
            row.push(
                <Box key={`${i}-${u}`} sx={{display: "flex", gap: "0.2px"}}>
                    <Box
                        key={`${i}-${u}-v1`}
                        id={`${i}-${u}-v1`}
                        sx={{
                            width: "35px",
                            height: "43px",
                            background: arrayColor[Math.floor(Math.random() * 4)],
                            transform: "skewY(-16deg)",
                            transition: "all 2s",
                        }}
                    />
                    <Box
                        key={`${i}-${u}-v2`}
                        id={`${i}-${u}-v2`}
                        sx={{
                            width: "35px",
                            height: "43px",
                            background: arrayColor[Math.floor(Math.random() * 4)],
                            transform: "skewY(16deg)",
                            transition: "all 2s",
                        }}
                    />
                </Box>
            );
        }
        layout.push(
            <Box
                key={`layout-${i}`}
                sx={{
                    display: "flex",
                    gap: "0.2px",
                }}
            >
                {row}
            </Box>
        );
    }
    return (
        <Box sx={{display: "flex", flexDirection: "column", gap: "0.2px"}}>
            {layout}
        </Box>
    );
};
// export default BackgroundAnimated;
export default memo(BackgroundAnimated);
