import {
    Box,
    Toolbar, useTheme,
} from "@mui/material";
import THCSMarcaSlogan from '../../Icons/THCSMarcaSlogan'
import React from "react";
import Houses from "../../Houses";
import AccordionArrow from "../../Accordion/Arrow";
import {GlobalContext} from "../../../contexts/GlobalContext";
import THCSIconHouse from "../../Icons/THCSIconHouse";
import THCSIconLocation from "../../Icons/THCSIconLocation";
import THCSIconNotifications from "../../Icons/THCSIconNotifications";
import THCSIconSettings from "../../Icons/THCSIconSettings";
import _ from "lodash";
import useAreas from "../../../services/useAreas";

interface ITopBar {
}

const TopBar: React.FC<ITopBar> = () => {
    const theme = useTheme();
    const {getAreas} = useAreas();

    const {
        filterChecks,
        setFilterChecks,
        areaChecks,
        setAreaChecks,
        configChecks,
        setConfigChecks,
        expandedTopBar,
        setExpandedTopBar,
    } = React.useContext(GlobalContext);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpandedTopBar(isExpanded ? panel : false);
        };

    React.useEffect(() => {
        if (getAreas.isSuccess) {
            let areaObj: any = {}
            _.map(getAreas.data, (area, index: number) => {
                areaObj[area.area_id] = {
                    value: areaChecks[area.area_id] ? areaChecks[area.area_id]?.value : true,
                    title: area.name,
                    type: 'CheckFilter',
                }
            })
            setAreaChecks(areaObj)
        }
    }, [getAreas.dataUpdatedAt])

    return (
        <Toolbar disableGutters sx={{
            backgroundColor: `primary.main`,
            height: 'auto',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            overflow: 'hidden',
            position: 'fixed',
            top: 0,
        }}
        >
            <THCSMarcaSlogan fill={theme.palette.common.white} height={'64px'} width={'250px'}
                             sx={{marginLeft: '10px'}}/>
            <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                <Houses/>
                <Houses/>
                <Houses/>
                <Houses/>

                <AccordionArrow
                    name={'Notificações'}
                    expanded={expandedTopBar}
                    setExpanded={setExpandedTopBar}
                    handleChange={handleChange}
                    color={theme.palette.secondary.main}
                    tittleColor={theme.palette.common.white}
                    icon={
                        <THCSIconNotifications fill={theme.palette.common.white} height={'30px'} width={'30px'}
                                               sx={{
                                                   position: 'relative',
                                                   bottom: '17px',
                                                   right: '40px',
                                                   height: '0px',
                                                   width: '0px',
                                               }}
                        />
                    }
                    key={'AccordionArrowNotifications'}
                    state={[]}
                    setState={() => false}
                />

                <AccordionArrow
                    name={'Área'}
                    expanded={expandedTopBar}
                    setExpanded={setExpandedTopBar}
                    handleChange={handleChange}
                    color={theme.palette.tertiary.main}
                    tittleColor={theme.palette.primary.main}
                    icon={
                        <THCSIconLocation fill={theme.palette.primary.main} height={'30px'} width={'30px'}
                                          sx={{
                                              position: 'relative',
                                              bottom: '17px',
                                              right: '40px',
                                              height: '0px',
                                              width: '0px',
                                          }}
                        />
                    }
                    key={'AccordionArrowLocations'}
                    state={areaChecks}
                    setState={setAreaChecks}
                >
                    Todos
                </AccordionArrow>

                <AccordionArrow
                    name={'Status'}
                    expanded={expandedTopBar}
                    setExpanded={setExpandedTopBar}
                    handleChange={handleChange}
                    color={theme.palette.common.white}
                    tittleColor={theme.palette.primary.main}
                    icon={
                        <THCSIconHouse fill={theme.palette.primary.main} inColor={theme.palette.common.white}
                                       height={'37px'} width={'37px'}
                                       sx={{
                                           position: 'relative',
                                           bottom: '21px',
                                           right: '44px',
                                           height: '0px',
                                           width: '0px',
                                       }}
                        />
                    }
                    key={'AccordionArrowStatus'}
                    state={filterChecks}
                    setState={setFilterChecks}
                >
                    Todos
                </AccordionArrow>

                <AccordionArrow
                    name={'Configurações'}
                    expanded={expandedTopBar}
                    setExpanded={setExpandedTopBar}
                    handleChange={handleChange}
                    color={theme.palette.primary.main}
                    tittleColor={theme.palette.common.white}
                    icon={
                        <THCSIconSettings fill={theme.palette.common.white} height={'32px'} width={'32px'}
                                          sx={{
                                              position: 'relative',
                                              bottom: '17px',
                                              right: '36px',
                                              height: '0px',
                                              width: '0px',
                                          }}
                        />
                    }
                    key={'AccordionArrowSettings'}
                    state={configChecks}
                    setState={setConfigChecks}
                />
            </Box>
        </Toolbar>
    );
};

export default TopBar;
