import {
    Alert,
    Box,
    Button, Checkbox,
    Container,
    FormControl, FormControlLabel,
    Grid, IconButton, InputAdornment, Link,
    OutlinedInput, Snackbar,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BackgroundAnimated from "../Background/Animated";
import THCStype1 from "../Icons/THCS_Type1";
import Copyright from "../Text/Copyright";
import BackgroundHouses from "../Background/Houses";
import {useCallback, useState} from "react";
import useAuth from "../../services/useAuth";
import Notifier, {INotifierProps} from "../Notifier";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import instance from "../../core/api";

export default function LogIn() {
    const theme = useTheme();

    // @ts-ignore
    const [notifier, setNotifier] = useState<INotifierProps>(null);

    const {verifyEmailMutation, loginMutation} = useAuth();

    const xsQuery = useMediaQuery(`(max-width:${theme.breakpoints.values.xs}px)`);
    const smQuery = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);
    const mdQuery = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
    const lgQuery = useMediaQuery(`(max-width:${theme.breakpoints.values.lg}px)`);
    const xlQuery = useMediaQuery(`(max-width:${theme.breakpoints.values.xl}px)`);

    const [showPassword, setShowPassword] = useState(false)
    const [showRecoveryPassword, setShowRecoveryPassword] = useState(false)
    const [sendEmail, setSendEmail] = useState(false)
    const [inputEmailForRecovery, setInputEmailForRecovery] = useState({
        value: "",
    })
    const [inputEmail, setInputEmail] = useState({value: "", error: false})
    const [inputPassword, setInputPassword] = useState({
        value: "",
        error: false,
    });
    const [user, setUser] = useState(false)
    const [loginToken, setLoginToken] = useState<string>("")

    const handleClickShowPassword = useCallback(() => {
        setShowPassword((prev) => !prev);
    }, []);

    const handleKeyEnter = async (e: any) => {
        if (e.key === "Enter") {
            if (!user) {
                await handleVerifyEmail(e)
            }
            await handleLogin(e)
        }
    };

    const handleVerifyEmail = useCallback(
        async (event: any) => {
            event.preventDefault();

            if (inputEmail.error) return;

            const verifyEmail = {
                email: inputEmail.value
            }
            verifyEmailMutation.mutate(verifyEmail, {
                onSuccess: (response) => {
                    if (response?.user) {
                        setUser(response.user)
                        instance.defaults.headers.common = {
                            token: response?.token?.token ?? '',
                        }
                    } else {
                        setNotifier({open: true, severity: 'error', msg: 'E-mail e/ou senha errada.'})
                        // <Snackbar open={response.user} autoHideDuration={6000}>
                        //     <Alert severity="success" sx={{ width: '100%' }}>
                        //         This is a success message!
                        //     </Alert>
                        // </Snackbar>
                    }
                    // set("user", response);
                    // setUser(response);
                    // instance.defaults.headers.common = {
                    //     Authorization: `bearer ${response.access_token}`,
                    //     company_id: response.company_id ? response.company_id : "",
                    // };
                    // navigate("/", { replace: true, state: { from: location } });
                },
                onError: (error) => {
                    // setNotifier({ open: true, severity: 'error', msg: error.errorMessage })
                },
                onSettled: () => {
                    // setLoading(false);
                },
            })
        },
        [inputPassword, inputEmail]
    );

    const handleLogin = useCallback(
        async (event: any) => {
            event.preventDefault();

            console.log(inputEmail, inputPassword)

            if (inputEmail.error || inputPassword.error) {
                return
            }

            const loginPayload = {
                email: inputEmail.value,
                password: inputPassword.value
            }

            loginMutation.mutate(loginPayload, {
                onSuccess: (response) => {
                    console.log(response)
                    // if (response?.user) {
                    //     setUser(response.user)
                    //     instance.defaults.headers.common = {
                    //         token: response?.token?.token ?? '',
                    //     }
                    // } else {
                    //     setNotifier({open: true, severity: 'error', msg: 'E-mail e/ou senha errada.'})
                    //     // <Snackbar open={response.user} autoHideDuration={6000}>
                    //     //     <Alert severity="success" sx={{ width: '100%' }}>
                    //     //         This is a success message!
                    //     //     </Alert>
                    //     // </Snackbar>
                    // }
                    // set("user", response);
                    // setUser(response);
                    // instance.defaults.headers.common = {
                    //     Authorization: `bearer ${response.access_token}`,
                    //     company_id: response.company_id ? response.company_id : "",
                    // };
                    // navigate("/", { replace: true, state: { from: location } });
                },
                onError: (error) => {
                    // setNotifier({ open: true, severity: 'error', msg: error.errorMessage })
                },
                onSettled: () => {
                    // setLoading(false);
                },
            })
        },
        [inputPassword, inputEmail]
    )


    return (
        <>
            <Notifier
                msg={notifier?.msg}
                closeState={setNotifier}
                severity={notifier?.severity}
                open={notifier?.open}
                closeTime={notifier?.closeTime}
                key={'top' + 'right'}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                slideDirection={'left'}
            />
            <Container
                sx={{
                    maxWidth: "none !important",
                    height: "calc(100vh - 24px)",
                    margin: 0,
                    backgroundColor: theme.palette.primary.main,
                    overflow: "hidden",
                    padding: "0px !important",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    "& .MuiFormHelperText-root": {fontWeight: 700},
                }}
            >
                <Box sx={{marginTop: "-20px"}}>
                    <BackgroundAnimated/>
                </Box>

                <Container
                    sx={{
                        width: "100%",
                        padding: "16px",
                        position: "absolute",
                        top: 0,
                        height: "100vh",
                        display: "flex",
                        alignItems: "center",
                        zIndex: 10,
                        maxWidth: "none !important",
                        // background: "pink",
                    }}
                >
                    <Grid
                        container
                        gap={2}
                        sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0 auto",
                            maxWidth: "1200px",
                            // padding: "0 1rem",
                        }}
                    >
                        <Grid
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            item
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                display="flex"
                                width={"25rem"}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <THCStype1
                                    fill={theme.palette.common.white}
                                    // width={"25rem"}
                                    width={"100%"}
                                />
                            </Box>
                        </Grid>
                        {/* TODO Implementar As Telas De Recuperação de Senha*/}
                        <Grid item sx={{width: "25rem"}}>
                            <Typography
                                fontWeight={500}
                                variant="body1"
                                align={mdQuery ? "center" : "left"}
                                color={"common.white"}
                            >
                                Bem-vindo(a)! Realize seu login para continuar:
                            </Typography>
                            <form
                                style={{
                                    width: "100%", // Fix IE 11 issue.
                                    marginTop: theme.spacing(1),
                                }}
                                noValidate
                            >
                                <FormControl
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-root.MuiInputBase-root": {
                                            "&.Mui-focused": {
                                                "& fieldset": {
                                                    border: `4px solid ${theme.palette.secondary.main} !important`,
                                                },
                                            },
                                            "&:hover": {
                                                "& fieldset": {
                                                    border: `4px solid ${theme.palette.tertiary.main}`,
                                                },
                                            },
                                        },
                                    }}
                                >
                                    <OutlinedInput
                                        sx={{
                                            background: "white",
                                        }}
                                        color="secondary"
                                        onKeyDown={handleKeyEnter}
                                        error={inputEmail.error}
                                        required
                                        fullWidth
                                        placeholder="E-mail"
                                        name="email"
                                        autoComplete="number"
                                        autoFocus
                                        onChange={(inputValue) =>
                                            setInputEmail((prev) => ({
                                                ...prev,
                                                value: inputValue.target.value,
                                            }))
                                        }
                                        id="outlined-adornment-email"
                                        value={inputEmail.value}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                {/* <div className={classes.wrapper}> */}
                                                {/* <Fab
                      className={classes.fab}
                      aria-label="save"
                      // color="secondary"
                      // className={buttonClassname}
                      onClick={handleVerifyEmail}
                      style={{ color: "primary" }}
                    > */}
                                                <IconButton
                                                    onClick={handleVerifyEmail}
                                                    sx={{
                                                        cursor: "pointer",
                                                        "& svg, path": {cursor: "pointer"},
                                                    }}
                                                >
                                                    <ArrowForwardIcon color="secondary"/>
                                                </IconButton>
                                                {/* </Fab> */}
                                                {/*<CircularProgress size={68} className={classes.fabProgress}/>*/}
                                                {/* </div> */}
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                {user ? (
                                    <>
                                        <FormControl fullWidth margin="normal" variant="outlined">
                                            <OutlinedInput
                                                sx={{
                                                    "&.MuiOutlinedInput-root.MuiInputBase-root": {
                                                        background: "white",
                                                        "&:hover": {
                                                            "& fieldset": {
                                                                border: `4px solid ${theme.palette.tertiary.main}`,
                                                            },
                                                        },
                                                        "&.Mui-focused": {
                                                            "& fieldset": {
                                                                border: `4px solid ${theme.palette.secondary.main} `,
                                                            },
                                                        },
                                                        "&.Mui-error": {
                                                            "& fieldset": {
                                                                border: `4px solid ${theme.palette.error.main}`,
                                                            },
                                                        },
                                                    },
                                                }}
                                                placeholder="senha"
                                                color="secondary"
                                                onKeyDown={handleKeyEnter}
                                                id="outlined-adornment-password"
                                                type={showPassword ? "text" : "password"}
                                                value={inputPassword.value}
                                                onChange={(inputValue) =>
                                                    setInputPassword((prev) => ({
                                                        ...prev,
                                                        value: inputValue.target.value,
                                                    }))
                                                }
                                                error={inputPassword.error}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            edge="end"
                                                            sx={{
                                                                // cursor: "pointer",
                                                                "& svg, path": {cursor: "pointer"},
                                                            }}
                                                        >
                                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                // labelWidth={70}
                                            />
                                        </FormControl>
                                        <FormControlLabel
                                            sx={{"& .MuiTypography-root": {color: "white"}}}
                                            control={
                                                <Checkbox
                                                    value="remember"
                                                    color="primary"
                                                    sx={{
                                                        "&.MuiCheckbox-root": {
                                                            "& svg": {color: theme.palette.tertiary.main},
                                                        },
                                                    }}
                                                />
                                            }
                                            label="Lembrar de mim neste computador"
                                        />
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            sx={{
                                                background: theme.palette.tertiary.main,
                                                color: theme.palette.text.primary,
                                                "&:hover": {background: theme.palette.tertiary.main},
                                            }}
                                            onClick={handleLogin}
                                        >
                                            Entrar
                                        </Button>
                                    </>
                                ) : (
                                    <>

                                    </>
                                )}
                            </form>
                        </Grid>
                        <Grid
                            item
                            sx={{
                                // backgroundColor: theme.palette.grey[200],
                                alignContent: "center",
                                // fontSize: "20px",
                                textDecoration: "bold",

                                borderTop: "1px solid #d9d9d9",
                                textAlign: "center",
                                padding: "0.1rem",
                                position: "fixed",
                                left: "0",
                                bottom: "0",
                                // height: "40px",
                                width: "100%",
                            }}
                        >
                            <Typography variant="body2" align="center"
                                        sx={{background: "#d9d9d9", fontSize: '0.875rem'}}>
                                <Link
                                    href="https://www.tascominformatica.com.br/"
                                    sx={{
                                        textDecoration: "none",
                                        "&:hover": {textDecoration: "underline"},
                                    }}
                                    target={"_blank"}
                                >
                                    TASCOM
                                </Link>
                                {" © "}
                                {new Date().getFullYear()}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
                <BackgroundHouses amountOfHouses={25}/>
            </Container>
        </>
    );
}
