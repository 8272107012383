import React, {ReactNode} from "react";
import {Box, Collapse} from "@mui/material";

interface IMenuCard {
    children?: ReactNode,
    status?: any,
    setStatus?: any,
    open?: boolean,
    width?: string,
}

const MenuCard: React.FC<IMenuCard> = ({children, open}: IMenuCard) => {

    return (
        <Collapse
            in={open}
            timeout={1000}
            orientation={'horizontal'}
            easing={{
                enter: 'ease',
                exit: 'ease'
            }}
            unmountOnExit
        >
            <Box sx={{
                display: 'flex',
                borderRadius: '10px',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                alignContent: 'center',
                flexWrap: 'nowrap',
                position: 'relative',
                zIndex: '1',
                maxHeight: '500px',
                width: 'auto',
                height: 'fit-content',
            }}>
                {children}
            </Box>
        </Collapse>
    )
}
export default MenuCard