import axios from "axios";
import useStateWithLocalStorage from "../hooks/useStateWithLocalStorage";
import {useNavigate} from "react-router-dom";
import {InitialValuePayload, IPayload} from "../pages/TokenPage";
import redirect from "../utils/redirect";

const instance = axios.create({
    baseURL:
        process.env.REACT_APP_API_DEV ||
        process.env.REACT_APP_API_PROD,
    timeout: 600000,
    // withCredentials: true,
});

instance.interceptors.request.use(
    function (config) {
        const payload: IPayload = JSON.parse(localStorage.getItem('THCS-MAP-PAYLOAD') ?? '{}') ?? InitialValuePayload

        config.headers!.token = payload.token;
        config.headers!.integration_url = payload.integration_url;
        config.headers!.integration_token = payload.integration_token;
        config.headers!.external_sector_id = payload.external_sector_id;
        config.headers!.company_id = payload.company_id;
        config.headers!.external_company_id = payload.external_company_id;
        config.headers!.external_user_id = payload.external_user_id;

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 403) {
            redirect(process.env.REACT_APP_HCS_HOST + '/login' ?? "", 7000)
        }
        return Promise.reject(error);
    }
)
export default instance;
