import React from "react";
import {Box} from "@mui/material";

interface IPropsSvg {
    fill: any;
    width?: any;
    height?: any;
    sx?: any;
}

export default function THCSCloud(props: IPropsSvg) {
    const {fill, width, height, sx} = props;

    return (
        <Box sx={{fill: fill, width: width, height: height, ...sx}}>
            <svg
                width={width ? width : "27"}
                height={height ? height : "11"}
                viewBox={`0 0 27 11`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M26.335 7.97786C26.335 6.61855 25.233 5.5166 23.8737 5.5166H10.0012V10.4391H23.8737C25.233 10.4391 26.335 9.33717 26.335 7.97786Z" fill={fill}/>
                <path d="M0 7.97786C0 6.61855 1.10194 5.5166 2.46126 5.5166H16.3338V10.4391H2.46126C1.10194 10.4391 0 9.33717 0 7.97786Z" fill={fill}/>
                <path d="M20.7411 5.76583C20.7411 2.90964 18.4257 0.594238 15.5695 0.594238H10.0011V8.42551H18.0814C19.5503 8.42551 20.7411 7.23473 20.7411 5.76583Z" fill={fill}/>
                <path d="M5.59387 5.76583C5.59387 2.90964 7.90927 0.594238 10.7655 0.594238H16.3339V8.42551H8.25355C6.78465 8.42551 5.59387 7.23473 5.59387 5.76583Z" fill={fill}/>
            </svg>
        </Box>
    );
}
