import React, {useEffect, useState} from "react";
import {Backdrop, Box, Card, CircularProgress, Fab, Typography, useTheme} from "@mui/material";
import useEvents from "../../services/useEvents";
import useAreas from "../../services/useAreas";
import handleText from "../../utils/text";
import TypographyText from "../Text";
import {green} from "@mui/material/colors";
import THCSIconHours from "../Icons/THCSIconHours";
import THCSIconCheck from "../Icons/THCSIconCheck";
import THCSIconCanceled from "../Icons/THCSIconCanceled";
import useHealth from "../../services/useHealth";
import useAuth from "../../services/useAuth";
import {toast} from "react-toastify";


const Loading: React.FC<any> = () => {
  const theme = useTheme();

  const [loadingIntegration, setLoadingIntegration] = useState<boolean | null>(true)
  const [loadingAuthentication, setLoadingAuthentication] = useState<boolean | null>(true)
  const [loadingArea, setLoadingArea] = useState<boolean | null>(true)
  const [errorArea, setErrorArea] = useState<string | null>(null)
  const [loadingPatient, setLoadingPatient] = useState<boolean | null>(true)
  const [errorPatient, setErrorPatient] = useState<string | null>(null)

  const {getEvents} = useEvents();
  const {getAreas} = useAreas();
  const {getHealth} = useHealth();
  const {getAuth} = useAuth();

  useEffect(() => {
    if (getAreas.isSuccess || getAreas.isError) {
      if (getAreas?.data?.length <= 0 || getAreas.isError) {
        setLoadingArea(null)
        setErrorArea(null)
        if (getAreas?.data?.length <= 0) {
          setErrorArea('Não existe área cadastrada')
        }
        if (getAreas.isError) {
          setErrorArea('')
        }
      } else {
        setLoadingArea(false)
        setErrorArea(null)
      }
    }
    if (getEvents.isSuccess || getEvents.isError) {
      if (getEvents?.data?.length <= 0 || getEvents.isError) {
        setLoadingPatient(null)
        setErrorPatient(null)
        if (getEvents?.data?.length <= 0) {
          setErrorPatient('Não existe paciente em atendimento')
        }
        if (getEvents.isError) {
          setErrorPatient('')
        }
      } else {
        setLoadingPatient(false)
        setErrorPatient(null)
      }
    }
    if (getHealth.isSuccess || getHealth.isError) {
      if (!getHealth?.data || getHealth.isError) {
        setLoadingIntegration(null)
      } else {
        setLoadingIntegration(false)
      }
    }

    if (getAuth.isSuccess || getAuth.isError) {
      if (!getAuth?.data || getAuth.isError || getAuth?.data?.code === 403) {
        setLoadingAuthentication(null)
        // toast.error(
        //     "Você não tem permissão para criar nova função."
        // )
      } else {
        setLoadingAuthentication(false)
      }
    }

  }, [getEvents, getAreas, getAuth, getHealth])

  const textSx = {
    color: theme.palette.common.white,
    fontSize: '24px',
    fontWeight: 'bold',
    paddingRight: '15px',
  }
  const errSx = {
    color: theme.palette.common.white,
    fontSize: '14px',
    fontWeight: 'bold',
    paddingRight: '15px',
  }

  return (
    <Backdrop
      sx={{
        color: '#fff',
        backgroundColor: 'transparent',
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={Boolean(
          loadingIntegration ||
          loadingAuthentication ||
          loadingArea ||
          loadingPatient ||
          errorArea !== null ||
          errorPatient !== null
      )}
    >
      <Card sx={{
        minWidth: 500,
        p:4,
        backgroundColor: theme.palette.primary.main
      }} variant="outlined">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: 'red'
          }}>
          {loadingAuthentication === null ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <TypographyText
                align={'center'}
                sx={textSx}
              >
                Token Expirado!
              </TypographyText>
              <TypographyText
                align={'center'}
                sx={textSx}
              >
                Redirecionando para a tela de login...
              </TypographyText>
            </Box>
          ) : (
            <Box
              sx={{

              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <TypographyText
                  sx={textSx}
                >
                  Verificando Integração
                </TypographyText>
                {
                  loadingIntegration ? (
                    <CircularProgress
                      size={24}
                      sx={{color: theme.palette.common.white}}
                    />
                  ) : loadingIntegration === null ? (
                    <THCSIconCanceled fill={theme.palette.error.main}/>
                  ) : (
                    <THCSIconCheck fill={theme.palette.success.main}/>
                  )
                }
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <TypographyText
                  sx={textSx}
                >
                  Realizando Autenticação
                </TypographyText>
                {
                  loadingAuthentication ? (
                    <CircularProgress
                      size={24}
                      sx={{color: theme.palette.common.white}}
                    />
                  ) : loadingAuthentication === null ? (
                    <THCSIconCanceled fill={theme.palette.error.main}/>
                  ) : (
                    <THCSIconCheck fill={theme.palette.success.main}/>
                  )
                }
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <TypographyText
                  sx={textSx}
                >
                  Carregando Áreas
                </TypographyText>
                {
                  loadingArea ? (
                    <CircularProgress
                      size={24}
                      sx={{color: theme.palette.common.white}}
                    />
                  ) : loadingArea === null ? (
                    <THCSIconCanceled fill={theme.palette.error.main}/>
                  ) : (
                    <THCSIconCheck fill={theme.palette.success.main}/>
                  )
                }
                <TypographyText
                  sx={errSx}
                >
                  {errorArea}
                </TypographyText>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <TypographyText
                  sx={textSx}
                >
                  Buscando Lista De Pacientes
                </TypographyText>
                {
                  loadingPatient ? (
                    <CircularProgress
                      size={24}
                      sx={{color: theme.palette.common.white}}
                    />
                  ) : loadingPatient === null ? (
                    <THCSIconCanceled fill={theme.palette.error.main}/>
                  ) : (
                    <THCSIconCheck fill={theme.palette.success.main}/>
                  )
                }
                <TypographyText
                  sx={errSx}
                >
                  {errorPatient}
                </TypographyText>
              </Box>
            </Box>
          )}
        </Box>
      </Card>
    </Backdrop>
  )
};
export default Loading;
