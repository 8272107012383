import React from "react";
import {Box} from "@mui/material";

interface IPropsSvg {
    colorBody?: any;
    colorWindow1?: any;
    colorWindow2?: any;
    width?: any;
    height?: any;
    sx?: any;
}

export default function THCSHouse3(props: IPropsSvg) {
    const {colorBody, colorWindow1, colorWindow2, width, height, sx} = props;

    return (
        <Box sx={{
            colorBody: colorBody,
            colorWindow1: colorWindow1,
            colorWindow2: colorWindow2,
            width: width,
            height: height,
            ...sx,
        }}>
            <svg
                width={width ? width : "103"}
                height={height ? height : "196"}
                viewBox={`0 0 103 196`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M0 196H102L102.5 15L51.5 0L0 15V196Z" fill={colorBody ? colorBody : "#F4F7FF"}/>
                <path d="M37 139H66V110L51.5 105L37 110V139Z" fill={colorWindow1 ? colorWindow1 : "#4949F3"}/>
                <path d="M37 86H66V57L51.5 52L37 57V86Z" fill={colorWindow2 ? colorWindow2 : "#4949F3"}/>
            </svg>
        </Box>
    );
}
