import React, {ReactNode} from "react";
import {Accordion, AccordionSummary, Box} from "@mui/material";
import ArrowButton from "../../Button/Arrow";
import MenuCard from "../../Card/MenuCard";
import GroupCheckBox from "../../CheckBox/Group";
import {GlobalContext} from "../../../contexts/GlobalContext";

interface IAccordion {
    children?: ReactNode,
    name: string,
    expanded: any
    setExpanded: any,
    handleChange: any,
    color: any,
    tittleColor: any,
    icon: any,
    state: any;
    setState: any;
}

const AccordionArrow: React.FC<IAccordion> = ({
                                                  name,
                                                  expanded,
                                                  setExpanded,
                                                  handleChange,
                                                  color,
                                                  tittleColor,
                                                  icon,
                                                  children,
                                                  state,
                                                  setState
                                              }: IAccordion) => {
    const {
        expandedTopBar,
    } = React.useContext(GlobalContext);

    return (
        <Box
            sx={{}}
        >
            <Accordion
                key={name}
                disableGutters={true}
                expanded={expanded === name}
                onChange={handleChange(name)}
                TransitionProps={{unmountOnExit: true}}
                sx={{backgroundColor: color}}
            >
                <AccordionSummary sx={{
                    padding: '0px',
                    '& .MuiAccordionSummary-content': {
                        margin: 0,
                    },
                }}
                >
                    <ArrowButton
                        name={name}
                        color={color}
                        tittleColor={tittleColor}
                        expanded={expanded === name}
                        setExpanded={setExpanded}
                    >
                        {icon}
                    </ArrowButton>
                </AccordionSummary>
            </Accordion>
            <Box
                sx={{
                    position: 'fixed',
                    top: '64px',
                    right: '0px',
                }}
                key={'AccordionAModalStatus'}
            >
                <MenuCard open={expandedTopBar === name} width={'200px'}>
                    <GroupCheckBox state={state} setState={setState}
                                   open={true} name={name}
                                   handleChange={handleChange}>
                        {children}
                    </GroupCheckBox>
                </MenuCard>
            </Box>
        </Box>
    )
}

export default AccordionArrow;
