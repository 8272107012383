import {useMutation, UseMutationResult, useQuery, UseQueryResult} from "react-query";
import instance from "../core/api";

export interface IUserEmail {
    email: string;
}

export interface IUserForm extends IUserEmail {
    password: string;
}

export interface IVerifiedEmail {
    message?: string;
    user?: boolean;
    token?: {
        auth?: boolean;
        token?: string;
    };
    password?: boolean;
}

export interface IError {
    message?: string;
    user?: boolean;
    auth?: boolean;
}


function useAuth() {
    const getAuth: UseQueryResult<any, any> = useQuery(
        'auth',
        async () => {
            const now = new Date().toISOString().split('T')[0]
            return instance.get(`/api/health/integration`).then((res) => res.data).catch((err) => {
                return {
                    message: err.message,
                    code: err.response.status,
                }
            })
        },
        {
            refetchInterval: 60000,
        }
    )

    const verifyEmailMutation: UseMutationResult<IVerifiedEmail, IError, IUserEmail> =
        useMutation(async (values) => {
            return instance.post("/api/user/checkemail", values).then((res) => res.data).catch((err) => {
                return {
                    message: err.message,
                    code: err.response.status,
                }
            });
        });

    const loginMutation: UseMutationResult<IVerifiedEmail, IError, IUserEmail> =
        useMutation(async (values) => {
            return instance.post("/api/user/login", values).then((res) => res.data);
        });

    return {
        getAuth,
        verifyEmailMutation,
        loginMutation,
    }
}

export default useAuth;