import React from "react";
import {Box} from "@mui/material";

interface IPropsSvg {
    fill: any;
    stroke?: any;
    width?: any;
    height?: any;
    sx?: any;
}

export default function THCSIconElipse(props: IPropsSvg) {
    const {fill, width, height, sx} = props;

    return (
        <Box sx={{fill: fill, width: width, height: height, ...sx}}>
            <svg
                width={width ? width : "19"}
                height={height ? height : "19"}
                viewBox={`0 0 19 19`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="9.58828" cy="9.41177" r="9.41177" fill={fill}/>
            </svg>
        </Box>
    );
}
