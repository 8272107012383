import {Box, Collapse, IconButton, Typography} from "@mui/material";
import React, {ReactNode} from "react";
import THCSLeftArrow from "../../Icons/THCSLeftArrow";
import handleText from "../../../utils/text";
import TypographyText from "../../Text";

interface IArrowButton {
    name?: string,
    color: any,
    tittleColor?: any,
    children?: ReactNode,
    expanded: boolean,
    setExpanded: any,
}

const ArrowButton: React.FC<IArrowButton> = ({
                                                 name,
                                                 color,
                                                 children,
                                                 tittleColor,
                                                 expanded,
                                                 setExpanded
                                             }: IArrowButton) => {
    return (
        <Box aria-label={'Arrow Button'}
             sx={{background: color}}
        >
            <IconButton size="large" aria-label="card-button" edge={'start'}
                        onClick={() => setExpanded(name)}
                        sx={{
                            padding: '0',
                            zIndex: '100',
                            backgroundColor: 'transparent !important',
                        }}>
                <THCSLeftArrow fill={color} height={'64px'} width={'47px'}/>
                {children}
                <Collapse
                    in={expanded}
                    timeout={1000}
                    orientation={'horizontal'}
                    easing={{
                        enter: 'ease-out',
                        exit: 'ease-in'
                    }}
                    unmountOnExit>
                    <TypographyText sx={{paddingRight: '20px', color: tittleColor, width: '100px'}}>
                        {name}
                    </TypographyText>
                </Collapse>
            </IconButton>
        </Box>
    );
};

export default ArrowButton;
