import React from "react";
import {Box} from "@mui/material";

interface IPropsSvg {
    fill: any;
    stroke?: any;
    width?: any;
    height?: any;
    sx?: any;
}

export default function THCSIconLocation(props: IPropsSvg) {
    const {fill, width, height, sx} = props;

    return (
        <Box sx={{fill: fill, width: width, height: height, ...sx}}>
            <svg
                width={width ? width : "16"}
                height={height ? height : "22"}
                viewBox={`0 0 16 22`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M8 22C6.55921 20.4216 5.22323 18.7573 4 17.017C2.17143 14.41 1.00445e-06 10.6832 1.00445e-06 7.70002C-0.000792022 6.17647 0.468011 4.68695 1.34708 3.41994C2.22615 2.15294 3.47597 1.16542 4.93836 0.582353C6.40076 -0.000713825 8.01 -0.153115 9.56242 0.144437C11.1148 0.441988 12.5407 1.17612 13.6594 2.25392C14.4043 2.96766 14.9947 3.81651 15.3964 4.75133C15.7982 5.68615 16.0033 6.68838 16 7.70002C16 10.6832 13.8286 14.41 12 17.0148C10.7769 18.7558 9.44087 20.4209 8 22ZM8 4.95002C7.43491 4.95002 6.88251 5.1113 6.41266 5.41347C5.9428 5.71565 5.5766 6.14514 5.36035 6.64764C5.14409 7.15013 5.08751 7.70307 5.19776 8.23651C5.308 8.76996 5.58012 9.25997 5.9797 9.64456C6.37927 10.0292 6.88837 10.2911 7.4426 10.3972C7.99683 10.5033 8.57131 10.4488 9.09338 10.2407C9.61546 10.0325 10.0617 9.68007 10.3756 9.22783C10.6896 8.7756 10.8571 8.24391 10.8571 7.70002C10.8571 6.97067 10.5561 6.2712 10.0203 5.75547C9.48449 5.23975 8.75776 4.95002 8 4.95002Z" fill={fill}/>
            </svg>
        </Box>
    );
}
