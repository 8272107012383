import React from "react";
import {Box} from "@mui/material";

interface IPropsSvg {
    fill: any;
    width?: any;
    height?: any;
    sx?: any;
}

export default function THCSIconNotifications(props: IPropsSvg) {
    const {fill, width, height, sx} = props;

    return (
        <Box sx={{fill: fill, width: width, height: height, ...sx}}>
            <svg
                width={width ? width : "16"}
                height={height ? height : "20"}
                viewBox={`0 0 16 20`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M8 19.5C8.52995 19.4984 9.03773 19.2872 9.41247 18.9125C9.7872 18.5377 9.99842 18.0299 10 17.5H6C6 18.0304 6.21071 18.5391 6.58579 18.9142C6.96086 19.2893 7.46957 19.5 8 19.5ZM14 13.5V8.5C14 5.43 12.36 2.86 9.5 2.18V1.5C9.5 1.10218 9.34196 0.720644 9.06066 0.43934C8.77936 0.158035 8.39782 0 8 0C7.60218 0 7.22064 0.158035 6.93934 0.43934C6.65804 0.720644 6.5 1.10218 6.5 1.5V2.18C3.63 2.86 2 5.42 2 8.5V13.5L0 15.5V16.5H16V15.5L14 13.5Z" fill={fill}/>
            </svg>
        </Box>
    );
}
