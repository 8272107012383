import React from "react";
import {Box} from "@mui/material";

interface IPropsSvg {
    fill: any;
    stroke?: any;
    width?: any;
    height?: any;
    sx?: any;
}

export default function THCSIconUserInjured(props: IPropsSvg) {
    const {fill, width, height, sx} = props;

    return (
        <Box sx={{fill: fill, width: width, height: height, ...sx}}>
            <svg
                width={width ? width : "30"}
                height={height ? height : "30"}
                viewBox={`0 0 30 30`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M15.0455 0.684933C14.1951 0.265569 13.2723 0.0336461 12.3338 0.0033966C11.3953 -0.0268529 10.4608 0.145209 9.58761 0.509024C8.71448 0.872838 7.92113 1.42073 7.25626 2.11908C6.59139 2.81744 6.06901 3.65152 5.72119 4.57014H10.1255L15.0455 0.684933ZM18.5788 4.57014C18.1475 3.47125 17.4678 2.50004 16.5988 1.74104L13.0099 4.57014H18.5629H18.5788ZM12.15 14.6249C13.9914 14.6249 15.7573 13.8545 17.0593 12.4831C18.3614 11.1117 19.0929 9.25176 19.0929 7.31235C19.082 7.00603 19.0529 6.70074 19.0056 6.39828H5.2944C5.24687 6.7007 5.21773 7.00601 5.20714 7.31235C5.20714 9.25176 5.93862 11.1117 7.24066 12.4831C8.5427 13.8545 10.3086 14.6249 12.15 14.6249ZM4.33929 17.1215V29.2433H11.2964L5.95601 16.5951C5.39934 16.703 4.85669 16.8797 4.33929 17.1215V17.1215ZM0 26.5078C0.00168047 27.2339 0.276726 27.9297 0.764809 28.4425C1.25289 28.9553 1.91416 29.2433 2.60357 29.2433V18.2945C1.79114 19.0081 1.13701 19.9005 0.687198 20.9089C0.237388 21.9174 0.00282228 23.0174 0 24.1315L0 26.5078ZM13.8857 23.7656H10.8823L13.1971 29.2433H13.8873C14.5778 29.2433 15.24 28.9544 15.7283 28.4401C16.2166 27.9259 16.4909 27.2284 16.4909 26.5011C16.4909 25.7738 16.2166 25.0763 15.7283 24.5621C15.24 24.0478 14.5778 23.7589 13.8873 23.7589L13.8857 23.7656ZM17.0097 16.453H16.1037C14.8641 17.0552 13.5152 17.3671 12.15 17.3671C10.7848 17.3671 9.43591 17.0552 8.19625 16.453H7.79643L10.1112 21.9375H13.8857C15.0362 21.9388 16.1392 22.4207 16.9527 23.2775C17.7662 24.1344 18.2237 25.2961 18.225 26.5078C18.2216 27.4999 17.9089 28.4634 17.3349 29.25H21.6964C22.3869 29.25 23.0492 28.9611 23.5374 28.4468C24.0257 27.9326 24.3 27.2351 24.3 26.5078V24.1315C24.2979 22.0958 23.5291 20.144 22.1624 18.7045C20.7957 17.2649 18.9425 16.4552 17.0097 16.453V16.453Z" fill={fill}/>
            </svg>
        </Box>
    );
}
